const Characters = {
    'asiatico': {width: 540, height: 1683, iconX: 317, iconY: 475, category: 'PAPA'},
    'asiatico_pelato': {width: 540, height: 1662, iconX: 319, iconY: 455, category: 'PAPA'},
    'baby_asiatico': {width: 374, height: 422, iconX: 220, iconY: 290, category: 'BEBE'},
    'baby_caucasico_biondo': {width: 374, height: 439, iconX: 220, iconY: 290, category: 'BEBE'},
    'baby_caucasico_castano': {width: 374, height: 439, iconX: 220, iconY: 290, category: 'BEBE'},
    'baby_caucasico_moro': {width: 374, height: 439, iconX: 220, iconY: 290, category: 'BEBE'},
    'baby_negroide': {width: 374, height: 431, iconX: 220, iconY: 290, category: 'BEBE'},
    'bambina_asiatica': {width: 385, height: 782, iconX: 228, iconY: 344, category: 'BIMBA'},
    'bambina_caucasica_bionda': {width: 385, height: 753, iconX: 228, iconY: 316, category: 'BIMBA'},
    'bambina_caucasica_castana': {width: 385, height: 753, iconX: 228, iconY: 316, category: 'BIMBA'},
    'bambina_caucasica_mora': {width: 385, height: 753, iconX: 228, iconY: 316, category: 'BIMBA'},
    'bambina_negroide': {width: 385, height: 773, iconX: 228, iconY: 336, category: 'BIMBA'},
    'bambino_asiatico': {width: 400, height: 751, iconX: 203, iconY: 327, category: 'BIMBO'},
    'bambino_caucasico_biondo': {width: 400, height: 758, iconX: 203, iconY: 334, category: 'BIMBO'},
    'bambino_caucasico_castano': {width: 400, height: 758, iconX: 203, iconY: 334, category: 'BIMBO'},
    'bambino_caucasico_moro': {width: 400, height: 758, iconX: 203, iconY: 334, category: 'BIMBO'},
    'bambino_negroide': {width: 400, height: 791, iconX: 203, iconY: 368, category: 'BIMBO'},
    'caucasico_biondo': {width: 540, height: 1714, iconX: 317, iconY: 506, category: 'PAPA'},
    'caucasico_biondo_barba': {width: 540, height: 1714, iconX: 317, iconY: 506, category: 'PAPA'},
    'caucasico_biondo_pelato': {width: 540, height: 1662, iconX: 317, iconY: 452, category: 'PAPA'},
    'caucasico_biondo_pelato_barba': {width: 540, height: 1662, iconX: 317, iconY: 452, category: 'PAPA'},
    'caucasico_castano': {width: 540, height: 1714, iconX: 317, iconY: 506, category: 'PAPA'},
    'caucasico_castano_barba': {width: 540, height: 1714, iconX: 317, iconY: 506, category: 'PAPA'},
    'caucasico_castano_pelato': {width: 540, height: 1662, iconX: 317, iconY: 452, category: 'PAPA'},
    'caucasico_castano_pelato_barba': {width: 540, height: 1662, iconX: 317, iconY: 452, category: 'PAPA'},
    'caucasico_moro': {width: 540, height: 1714, iconX: 317, iconY: 506, category: 'PAPA'},
    'caucasico_moro_barba': {width: 540, height: 1714, iconX: 317, iconY: 506, category: 'PAPA'},
    'caucasico_moro_pelato': {width: 540, height: 1662, iconX: 317, iconY: 452, category: 'PAPA'},
    'caucasico_moro_barba_pelato': {width: 540, height: 1662, iconX: 317, iconY: 452, category: 'PAPA'},
    'mamma_asiatica': {width: 423, height: 1524, iconX: 294, iconY: 434, category: 'MAMMA'},
    'mamma_caucasica_bionda': {width: 423, height: 1505, iconX: 294, iconY: 412, category: 'MAMMA'},
    'mamma_caucasica_castana': {width: 423, height: 1505, iconX: 294, iconY: 412, category: 'MAMMA'},
    'mamma_caucasica_mora': {width: 423, height: 1505, iconX: 294, iconY: 412, category: 'MAMMA'},
    'mamma_negroide': {width: 431, height: 1550, iconX: 294, iconY: 462, category: 'MAMMA'},
    'negroide': {width: 540, height: 1750, iconX: 318, iconY: 544, category: 'PAPA'},
    'negroide_barba': {width: 540, height: 1750, iconX: 318, iconY: 544, category: 'PAPA'},
    'negroide_pelato': {width: 540, height: 1662, iconX: 318, iconY: 452, category: 'PAPA'},
    'nonna_asiatica': {width: 423, height: 1436, iconX: 292, iconY: 418, category: 'NONNA'},
    'nonna_caucasica': {width: 423, height: 1436, iconX: 292, iconY: 418, category: 'NONNA'},
    'nonna_negroide': {width: 423, height: 1442, iconX: 294, iconY: 424, category: 'NONNA'},
    'nonno_asiatico': {width: 540, height: 1622, iconX: 318, iconY: 454, category: 'NONNO'},
    'nonno_caucasico': {width: 540, height: 1622, iconX: 318, iconY: 454, category: 'NONNO'},
    'nonno_negroide': {width: 540, height: 1622, iconX: 318, iconY: 454, category: 'NONNO'},
    'ragazza_asiatica': {width: 425, height: 1435, iconX: 258, iconY: 428, category: 'RAGAZZA'},
    'ragazza_caucasica_bionda': {width: 425, height: 1425, iconX: 258, iconY: 416, category: 'RAGAZZA'},
    'ragazza_caucasica_castana': {width: 425, height: 1425, iconX: 258, iconY: 416, category: 'RAGAZZA'},
    'ragazza_caucasica_mora': {width: 425, height: 1425, iconX: 258, iconY: 416, category: 'RAGAZZA'},
    'ragazza_negreoide': {width: 425, height: 1433, iconX: 258, iconY: 422, category: 'RAGAZZA'},
    'ragazzo_asiatico': {width: 492, height: 1525, iconX: 280, iconY: 504, category: 'RAGAZZO'},
    'ragazzo_caucasico': {width: 492, height: 1487, iconX: 280, iconY: 470, category: 'RAGAZZO'},
    'ragazzo_caucasico_biondo': {width: 492, height: 1487, iconX: 280, iconY: 470, category: 'RAGAZZO'},
    'ragazzo_caucasico_moro': {width: 492, height: 1487, iconX: 280, iconY: 470, category: 'RAGAZZO'},
    'ragazzo_negroide': {width: 492, height: 1510, iconX: 280, iconY: 494, category: 'RAGAZZO'},
};

export default Characters;