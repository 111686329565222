import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Bootbox  from  'bootbox-react';
import Form from 'react-bootstrap/Form';

import Button from '../components/Button';
import Footer from '../components/Footer';

import { setSurveyCharacters, setTitle } from '../store/actions';

import CharacterImages from '../components/CharacterImages';

const Variants = [
  'disgusto', 'gioia', 'neutro', 'paura', 'rabbia', 'tristezza'
];

const EditFeelings = props => {
  const history = useHistory();
  const { match: { params: { id } } } = props;
  
  const [alert, setAlert] = useState('');
  const [values, setValues] = useState({
    id: id,
    characters: props.characters || []
  });

  const { setSurveyCharacters, setTitle } = props;
  setTimeout(() => setTitle('THINK UP FAMILY LIFE'), 100);

  const next = () => {
    if (values.characters.length === 0) {
      setAlert('Attenzione, seleziona almeno un familiare prima di proseguire');
    } else {
      setSurveyCharacters(values.characters);
      history.push("/" + values.id + "/activity")
    }
  }

  const setCharacterVariant = (e, item, variant) => {
    e.preventDefault();
    var newCharacters = values.characters.map(character => {
      if (character.id === item.id) {
        character.variant = variant;
      }
      return character;
    });
    setValues({...values, characters: newCharacters });
  }

  return (
    <>
      <div className="container-padding">
        <h5 className="h5">
          <strong>TERZO STEP</strong>: SCEGLI L'EMOZIONE
        </h5>
        <Form>
          <div className="row">
            <div className="col-8 mt30">
              {values.characters.map(item => (
                <div key={item.id}>
                  {Variants.map(variant => (
                    <a key={`variant-${variant}`} className={`img-rounded ${item.variant !== variant ? 'img-rounded-unselected' : 'img-rounded-selected'}`} href="/#" onClick={e => setCharacterVariant(e, item, variant)}>
                      <img src={CharacterImages[`${item.key}_${variant}`]} alt="" />
                    </a>
                  ))}
                </div>
              ))}
            </div>
            <div className="col-4 border-left mt30">
              <h5 className="h5 mt20 mb30">
                PERSONAGGI SELEZIONATI
              </h5>
              {values.characters.map(item => (
                <div key={item.id} className="row bg-gray">
                  <div className="col-sm-2 mt5 text-center">
                    <a className="img-rounded-small" href="/#">
                      <img src={CharacterImages[`${item.key}_${item.variant}`]} alt="" />
                    </a>
                  </div>
                  <div className="col-sm-6 mt40">
                    <Form.Group controlId="name">
                      <Form.Control type="text" name="name" placeholder="Nome personaggio..." defaultValue={item.name} disabled={true} />
                    </Form.Group>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Form>
      </div>
      <Footer left={(
        <Link to={`/${id}/characters`}>
          <Button label="Indietro" icon={faArrowLeft} iconPosition="left" />
        </Link>
      )} right={(
        <Button label="Prosegui" icon={faArrowRight} iconPosition="right" onClick={next} />
      )} />
      <Bootbox show={alert !== ''} 
          type={"alert"}  
          message={alert}  
          onClose={() => setAlert('')} 
      />
    </>
  );
}

const mapStateToProps = state => ({
  characters: state.survey.characters
});

const mapDispatchToProps = dispatch => bindActionCreators({ setSurveyCharacters, setTitle }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFeelings)