import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

import Bootbox  from  'bootbox-react';
import Form from 'react-bootstrap/Form';

import Button from '../components/Button';
import Footer from '../components/Footer';
import { Rooms, RoomsImages } from '../components/Rooms';

import { setSurveyBackground, setTitle } from '../store/actions';

import ImgCasa from '../imgs/casa.png';

import './EditBackground.scss';

const EditBackground = props => {
  const history = useHistory();
  const { match: { params: { id } } } = props;
  
  const [alert, setAlert] = useState('');
  const [values, setValues] = useState({
    id: id,
    nightMode: props.nightMode,
    room: props.room,
    name: props.name
  });

  const { setSurveyBackground, setTitle } = props;
  setTimeout(() => setTitle('THINK UP FAMILY LIFE'), 100);

  const updateValues = e => {
    const {name, value} = e.target;
    setValues({...values, [name]: value});
  }

  const next = () => {
    if (!values.room) {
      setAlert('Attenzione, seleziona un ambiente prima di proseguire');
    } else {
      setSurveyBackground(values.room, values.name, values.nightMode);
      history.push("/" + values.id + "/characters")
    }
  }

  return (
    <>
      <div className="container-padding">
        <h5 className="h5">
          <strong>PRIMO STEP</strong>: SCEGLI L'AMBIENTE
          <small>
            Seleziona la stanza della casa dove vuoi rappresentare la tua famiglia<br />
            e scegli in quale momento della giornata
          </small>
        </h5>
        <Form>
          <div className="row mt30">
            <div className="col-9">
              <div className="room-selection">
                <img src={ImgCasa} alt="" className="img-fluid" />
                {Rooms.map((room, i) => {
                  const night = values.nightMode ? 'notte' : 'giorno';
                  return (
                    <div className={`room-${i} mb30`} key={room.id}>
                      <img src={RoomsImages[`${room.id}_${night}`]} alt="" className={`img-fluid ${values.room === room.id ? 'img-selected' : 'img-unselected'}`} onClick={() => {updateValues({target: {name: "room", value: room.id}})}} />
                      <Form.Check 
                        type="checkbox"
                        className="mt5"
                        id={room.id}
                        label={room.name}
                        checked={values.room === room.id}
                        onChange={() => {updateValues({target: {name: "room", value: room.id}})}}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col-3">
              <Form.Group controlId="nightMode">
              <div className="mb-3">
                  <Form.Check 
                    type="radio"
                    id="nightMode-false"
                    checked={!values.nightMode}
                    onChange={() => {updateValues({target: {name: "nightMode", value: !values.nightMode}})}}
                    label={(
                      <>
                        <FontAwesomeIcon icon={faSun} /> Giorno
                      </>
                    )}
                  />
                </div>
                <div className="mb40">
                  <Form.Check 
                    type="radio"
                    id="nightMode-false"
                    checked={!!values.nightMode}
                    onChange={() => {updateValues({target: {name: "nightMode", value: !values.nightMode}})}}
                    label={(
                      <>
                        <FontAwesomeIcon icon={faMoon} /> Notte
                      </>
                    )}
                  />
                </div>
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>Attribuisci un titolo alla situazione che andrai a descrivere (facoltativo)</Form.Label>
                <Form.Control type="text" name="name" placeholder="Titolo..." defaultValue={values.name} onChange={updateValues} />
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>
      <Footer left={(
        <Link to={`/${id}/edit`}>
          <Button label="Indietro" icon={faArrowLeft} iconPosition="left" />
        </Link>
      )} right={(
        <Button label="Prosegui" icon={faArrowRight} iconPosition="right" onClick={next} />
      )} />
      <Bootbox show={alert !== ''} 
          type={"alert"}  
          message={alert}  
          onClose={() => setAlert('')} 
      />
    </>
  );
}

const mapStateToProps = state => ({
  room: state.survey.room,
  nightMode: state.survey.nightMode,
  name: state.survey.name
});

const mapDispatchToProps = dispatch => bindActionCreators({ setSurveyBackground, setTitle }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBackground)