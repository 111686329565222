import React from 'react';
import { useHistory } from 'react-router-dom';

const Splash = () => {
    const history = useHistory();
    const next = () => {
        history.push("/home")
    };
    return (
        <div className="splash" onClick={next}></div>
    );
}

export default Splash;