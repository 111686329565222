import CAMERETTA_SINGOLA_giorno from '../imgs/CAMERETTA_SINGOLA_giorno.png';
import CAMERETTA_SINGOLA_notte from '../imgs/CAMERETTA_SINGOLA_notte.png';
import CAMERA_giorno from '../imgs/CAMERA_giorno.png';
import CAMERA_notte from '../imgs/CAMERA_notte.png';
import CAMERETTA_DOPPIA_giorno from '../imgs/CAMERETTA_DOPPIA_giorno.png';
import CAMERETTA_DOPPIA_notte from '../imgs/CAMERETTA_DOPPIA_notte.png';
import SALOTTO_giorno from '../imgs/SALOTTO_giorno.png';
import SALOTTO_notte from '../imgs/SALOTTO_notte.png';
import CUCINA_giorno from '../imgs/CUCINA_giorno.png';
import CUCINA_notte from '../imgs/CUCINA_notte.png';
import BAGNO_giorno from '../imgs/BAGNO_giorno.png';
import BAGNO_notte from '../imgs/BAGNO_notte.png';


export const RoomsImages = {
  'CAMERETTA_SINGOLA_giorno': CAMERETTA_SINGOLA_giorno,
  'CAMERETTA_SINGOLA_notte': CAMERETTA_SINGOLA_notte,
  'CAMERA_giorno': CAMERA_giorno,
  'CAMERA_notte': CAMERA_notte,
  'CAMERETTA_DOPPIA_giorno': CAMERETTA_DOPPIA_giorno,
  'CAMERETTA_DOPPIA_notte': CAMERETTA_DOPPIA_notte,
  'CUCINA_giorno': CUCINA_giorno,
  'CUCINA_notte': CUCINA_notte,
  'SALOTTO_giorno': SALOTTO_giorno,
  'SALOTTO_notte': SALOTTO_notte,
  'BAGNO_giorno': BAGNO_giorno,
  'BAGNO_notte': BAGNO_notte,
};

export const Rooms = [
    {
      'id': 'CAMERETTA_SINGOLA',
      'name': 'Cameretta Singola'
    },
    {
      'id': 'CAMERA',
      'name': 'Camera'
    },
    {
      'id': 'CAMERETTA_DOPPIA',
      'name': 'Cameretta Doppia'
    },
    {
      'id': 'CUCINA',
      'name': 'Cucina'
    },
    {
      'id': 'SALOTTO',
      'name': 'Salotto'
    },
    {
      'id': 'BAGNO',
      'name': 'Bagno'
    },
];

export default Rooms;
