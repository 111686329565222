import React from 'react';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';

import './App.scss';

import { Provider } from 'react-redux'
import store from './store/store'

import Header from './components/Header';

import Home from './views/Home';
import Splash from './views/Splash';
import EditConfiguration from './views/EditConfiguration';
import EditBackground from './views/EditBackground';
import EditCharacters from './views/EditCharacters';
import EditFeelings from './views/EditFeelings';
import EditActivity from './views/EditActivity';
import EditPosition from './views/EditPosition';
import EditAnswers from './views/EditAnswers';
import EditPreview from './views/EditPreview';
import View from './views/View';


function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="app">
          <Header />
          <div className="app-container">
            <Switch>
              <Route exact path="/" component={Splash} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/:id" component={View} />
              <Route exact path="/:id/edit" component={EditConfiguration} />
              <Route exact path="/:id/background" component={EditBackground} />
              <Route exact path="/:id/characters" component={EditCharacters} />
              <Route exact path="/:id/feelings" component={EditFeelings} />
              <Route exact path="/:id/activity" component={EditActivity} />
              <Route exact path="/:id/position" component={EditPosition} />
              <Route exact path="/:id/preview" component={EditPreview} />
              <Route exact path="/:id/survey" component={EditAnswers} />
            </Switch>
          </div>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
