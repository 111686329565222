import asiatico_disgusto from '../imgs/characters/asiatico_disgusto.png';
import asiatico_gioia from '../imgs/characters/asiatico_gioia.png';
import asiatico_neutro from '../imgs/characters/asiatico_neutro.png';
import asiatico_paura from '../imgs/characters/asiatico_paura.png';
import asiatico_pelato_disgusto from '../imgs/characters/asiatico_pelato_disgusto.png';
import asiatico_pelato_gioia from '../imgs/characters/asiatico_pelato_gioia.png';
import asiatico_pelato_neutro from '../imgs/characters/asiatico_pelato_neutro.png';
import asiatico_pelato_paura from '../imgs/characters/asiatico_pelato_paura.png';
import asiatico_pelato_rabbia from '../imgs/characters/asiatico_pelato_rabbia.png';
import asiatico_pelato_tristezza from '../imgs/characters/asiatico_pelato_tristezza.png';
import asiatico_rabbia from '../imgs/characters/asiatico_rabbia.png';
import asiatico_tristezza from '../imgs/characters/asiatico_tristezza.png';
import baby_asiatico_neutro from '../imgs/characters/baby_asiatico_neutro.png';
import baby_asiatico_tristezza from '../imgs/characters/baby_asiatico_tristezza.png';
import baby_caucasico_biondo_neutro from '../imgs/characters/baby_caucasico_biondo_neutro.png';
import baby_caucasico_biondo_tristezza from '../imgs/characters/baby_caucasico_biondo_tristezza.png';
import baby_caucasico_castano_neutro from '../imgs/characters/baby_caucasico_castano_neutro.png';
import baby_caucasico_castano_tristezza from '../imgs/characters/baby_caucasico_castano_tristezza.png';
import baby_caucasico_moro_neutro from '../imgs/characters/baby_caucasico_moro_neutro.png';
import baby_caucasico_moro_tristezza from '../imgs/characters/baby_caucasico_moro_tristezza.png';
import baby_negroide_neutro from '../imgs/characters/baby_negroide_neutro.png';
import baby_negroide_tristezza from '../imgs/characters/baby_negroide_tristezza.png';
import bambina_asiatica_disgusto from '../imgs/characters/bambina_asiatica_disgusto.png';
import bambina_asiatica_gioia from '../imgs/characters/bambina_asiatica_gioia.png';
import bambina_asiatica_neutro from '../imgs/characters/bambina_asiatica_neutro.png';
import bambina_asiatica_paura from '../imgs/characters/bambina_asiatica_paura.png';
import bambina_asiatica_rabbia from '../imgs/characters/bambina_asiatica_rabbia.png';
import bambina_asiatica_tristezza from '../imgs/characters/bambina_asiatica_tristezza.png';
import bambina_caucasica_bionda_disgusto from '../imgs/characters/bambina_caucasica_bionda_disgusto.png';
import bambina_caucasica_bionda_gioia from '../imgs/characters/bambina_caucasica_bionda_gioia.png';
import bambina_caucasica_bionda_neutro from '../imgs/characters/bambina_caucasica_bionda_neutro.png';
import bambina_caucasica_bionda_paura from '../imgs/characters/bambina_caucasica_bionda_paura.png';
import bambina_caucasica_bionda_rabbia from '../imgs/characters/bambina_caucasica_bionda_rabbia.png';
import bambina_caucasica_bionda_tristezza from '../imgs/characters/bambina_caucasica_bionda_tristezza.png';
import bambina_caucasica_castana_disgusto from '../imgs/characters/bambina_caucasica_castana_disgusto.png';
import bambina_caucasica_castana_gioia from '../imgs/characters/bambina_caucasica_castana_gioia.png';
import bambina_caucasica_castana_neutro from '../imgs/characters/bambina_caucasica_castana_neutro.png';
import bambina_caucasica_castana_paura from '../imgs/characters/bambina_caucasica_castana_paura.png';
import bambina_caucasica_castana_rabbia from '../imgs/characters/bambina_caucasica_castana_rabbia.png';
import bambina_caucasica_castana_tristezza from '../imgs/characters/bambina_caucasica_castana_tristezza.png';
import bambina_caucasica_mora_disgusto from '../imgs/characters/bambina_caucasica_mora_disgusto.png';
import bambina_caucasica_mora_gioia from '../imgs/characters/bambina_caucasica_mora_gioia.png';
import bambina_caucasica_mora_neutro from '../imgs/characters/bambina_caucasica_mora_neutro.png';
import bambina_caucasica_mora_paura from '../imgs/characters/bambina_caucasica_mora_paura.png';
import bambina_caucasica_mora_rabbia from '../imgs/characters/bambina_caucasica_mora_rabbia.png';
import bambina_caucasica_mora_tristezza from '../imgs/characters/bambina_caucasica_mora_tristezza.png';
import bambina_negroide_disgusto from '../imgs/characters/bambina_negroide_disgusto.png';
import bambina_negroide_gioia from '../imgs/characters/bambina_negroide_gioia.png';
import bambina_negroide_neutro from '../imgs/characters/bambina_negroide_neutro.png';
import bambina_negroide_paura from '../imgs/characters/bambina_negroide_paura.png';
import bambina_negroide_rabbia from '../imgs/characters/bambina_negroide_rabbia.png';
import bambina_negroide_tristezza from '../imgs/characters/bambina_negroide_tristezza.png';
import bambino_asiatico_disgusto from '../imgs/characters/bambino_asiatico_disgusto.png';
import bambino_asiatico_gioia from '../imgs/characters/bambino_asiatico_gioia.png';
import bambino_asiatico_neutro from '../imgs/characters/bambino_asiatico_neutro.png';
import bambino_asiatico_paura from '../imgs/characters/bambino_asiatico_paura.png';
import bambino_asiatico_rabbia from '../imgs/characters/bambino_asiatico_rabbia.png';
import bambino_asiatico_tristezza from '../imgs/characters/bambino_asiatico_tristezza.png';
import bambino_caucasico_biondo_disgusto from '../imgs/characters/bambino_caucasico_biondo_disgusto.png';
import bambino_caucasico_biondo_gioia from '../imgs/characters/bambino_caucasico_biondo_gioia.png';
import bambino_caucasico_biondo_neutro from '../imgs/characters/bambino_caucasico_biondo_neutro.png';
import bambino_caucasico_biondo_paura from '../imgs/characters/bambino_caucasico_biondo_paura.png';
import bambino_caucasico_biondo_rabbia from '../imgs/characters/bambino_caucasico_biondo_rabbia.png';
import bambino_caucasico_biondo_tristezza from '../imgs/characters/bambino_caucasico_biondo_tristezza.png';
import bambino_caucasico_castano_disgusto from '../imgs/characters/bambino_caucasico_castano_disgusto.png';
import bambino_caucasico_castano_gioia from '../imgs/characters/bambino_caucasico_castano_gioia.png';
import bambino_caucasico_castano_neutro from '../imgs/characters/bambino_caucasico_castano_neutro.png';
import bambino_caucasico_castano_paura from '../imgs/characters/bambino_caucasico_castano_paura.png';
import bambino_caucasico_castano_rabbia from '../imgs/characters/bambino_caucasico_castano_rabbia.png';
import bambino_caucasico_castano_tristezza from '../imgs/characters/bambino_caucasico_castano_tristezza.png';
import bambino_caucasico_moro_disgusto from '../imgs/characters/bambino_caucasico_moro_disgusto.png';
import bambino_caucasico_moro_gioia from '../imgs/characters/bambino_caucasico_moro_gioia.png';
import bambino_caucasico_moro_neutro from '../imgs/characters/bambino_caucasico_moro_neutro.png';
import bambino_caucasico_moro_paura from '../imgs/characters/bambino_caucasico_moro_paura.png';
import bambino_caucasico_moro_rabbia from '../imgs/characters/bambino_caucasico_moro_rabbia.png';
import bambino_caucasico_moro_tristezza from '../imgs/characters/bambino_caucasico_moro_tristezza.png';
import bambino_negroide_disgusto from '../imgs/characters/bambino_negroide_disgusto.png';
import bambino_negroide_gioia from '../imgs/characters/bambino_negroide_gioia.png';
import bambino_negroide_neutro from '../imgs/characters/bambino_negroide_neutro.png';
import bambino_negroide_paura from '../imgs/characters/bambino_negroide_paura.png';
import bambino_negroide_rabbia from '../imgs/characters/bambino_negroide_rabbia.png';
import bambino_negroide_tristezza from '../imgs/characters/bambino_negroide_tristezza.png';
import caucasico_biondo_barba_disgusto from '../imgs/characters/caucasico_biondo_barba_disgusto.png';
import caucasico_biondo_barba_gioia from '../imgs/characters/caucasico_biondo_barba_gioia.png';
import caucasico_biondo_barba_neutro from '../imgs/characters/caucasico_biondo_barba_neutro.png';
import caucasico_biondo_barba_paura from '../imgs/characters/caucasico_biondo_barba_paura.png';
import caucasico_biondo_barba_rabbia from '../imgs/characters/caucasico_biondo_barba_rabbia.png';
import caucasico_biondo_barba_tristezza from '../imgs/characters/caucasico_biondo_barba_tristezza.png';
import caucasico_biondo_disgusto from '../imgs/characters/caucasico_biondo_disgusto.png';
import caucasico_biondo_gioia from '../imgs/characters/caucasico_biondo_gioia.png';
import caucasico_biondo_neutro from '../imgs/characters/caucasico_biondo_neutro.png';
import caucasico_biondo_paura from '../imgs/characters/caucasico_biondo_paura.png';
import caucasico_biondo_pelato_barba_disgusto from '../imgs/characters/caucasico_biondo_pelato_barba_disgusto.png';
import caucasico_biondo_pelato_barba_gioia from '../imgs/characters/caucasico_biondo_pelato_barba_gioia.png';
import caucasico_biondo_pelato_barba_neutro from '../imgs/characters/caucasico_biondo_pelato_barba_neutro.png';
import caucasico_biondo_pelato_barba_paura from '../imgs/characters/caucasico_biondo_pelato_barba_paura.png';
import caucasico_biondo_pelato_barba_rabbia from '../imgs/characters/caucasico_biondo_pelato_barba_rabbia.png';
import caucasico_biondo_pelato_barba_tristezza from '../imgs/characters/caucasico_biondo_pelato_barba_tristezza.png';
import caucasico_biondo_pelato_disgusto from '../imgs/characters/caucasico_biondo_pelato_disgusto.png';
import caucasico_biondo_pelato_gioia from '../imgs/characters/caucasico_biondo_pelato_gioia.png';
import caucasico_biondo_pelato_neutro from '../imgs/characters/caucasico_biondo_pelato_neutro.png';
import caucasico_biondo_pelato_paura from '../imgs/characters/caucasico_biondo_pelato_paura.png';
import caucasico_biondo_pelato_rabbia from '../imgs/characters/caucasico_biondo_pelato_rabbia.png';
import caucasico_biondo_pelato_tristezza from '../imgs/characters/caucasico_biondo_pelato_tristezza.png';
import caucasico_biondo_rabbia from '../imgs/characters/caucasico_biondo_rabbia.png';
import caucasico_biondo_tristezza from '../imgs/characters/caucasico_biondo_tristezza.png';
import caucasico_castano_barba_disgusto from '../imgs/characters/caucasico_castano_barba_disgusto.png';
import caucasico_castano_barba_gioia from '../imgs/characters/caucasico_castano_barba_gioia.png';
import caucasico_castano_barba_neutro from '../imgs/characters/caucasico_castano_barba_neutro.png';
import caucasico_castano_barba_paura from '../imgs/characters/caucasico_castano_barba_paura.png';
import caucasico_castano_barba_rabbia from '../imgs/characters/caucasico_castano_barba_rabbia.png';
import caucasico_castano_barba_tristezza from '../imgs/characters/caucasico_castano_barba_tristezza.png';
import caucasico_castano_disgusto from '../imgs/characters/caucasico_castano_disgusto.png';
import caucasico_castano_gioia from '../imgs/characters/caucasico_castano_gioia.png';
import caucasico_castano_neutro from '../imgs/characters/caucasico_castano_neutro.png';
import caucasico_castano_paura from '../imgs/characters/caucasico_castano_paura.png';
import caucasico_castano_pelato_barba_disgusto from '../imgs/characters/caucasico_castano_pelato_barba_disgusto.png';
import caucasico_castano_pelato_barba_gioia from '../imgs/characters/caucasico_castano_pelato_barba_gioia.png';
import caucasico_castano_pelato_barba_neutro from '../imgs/characters/caucasico_castano_pelato_barba_neutro.png';
import caucasico_castano_pelato_barba_paura from '../imgs/characters/caucasico_castano_pelato_barba_paura.png';
import caucasico_castano_pelato_barba_rabbia from '../imgs/characters/caucasico_castano_pelato_barba_rabbia.png';
import caucasico_castano_pelato_barba_tristezza from '../imgs/characters/caucasico_castano_pelato_barba_tristezza.png';
import caucasico_castano_pelato_disgusto from '../imgs/characters/caucasico_castano_pelato_disgusto.png';
import caucasico_castano_pelato_gioia from '../imgs/characters/caucasico_castano_pelato_gioia.png';
import caucasico_castano_pelato_neutro from '../imgs/characters/caucasico_castano_pelato_neutro.png';
import caucasico_castano_pelato_paura from '../imgs/characters/caucasico_castano_pelato_paura.png';
import caucasico_castano_pelato_rabbia from '../imgs/characters/caucasico_castano_pelato_rabbia.png';
import caucasico_castano_pelato_tristezza from '../imgs/characters/caucasico_castano_pelato_tristezza.png';
import caucasico_castano_rabbia from '../imgs/characters/caucasico_castano_rabbia.png';
import caucasico_castano_tristezza from '../imgs/characters/caucasico_castano_tristezza.png';
import caucasico_moro_barba_disgusto from '../imgs/characters/caucasico_moro_barba_disgusto.png';
import caucasico_moro_barba_gioia from '../imgs/characters/caucasico_moro_barba_gioia.png';
import caucasico_moro_barba_neutro from '../imgs/characters/caucasico_moro_barba_neutro.png';
import caucasico_moro_barba_paura from '../imgs/characters/caucasico_moro_barba_paura.png';
import caucasico_moro_barba_pelato_disgusto from '../imgs/characters/caucasico_moro_barba_pelato_disgusto.png';
import caucasico_moro_barba_pelato_gioia from '../imgs/characters/caucasico_moro_barba_pelato_gioia.png';
import caucasico_moro_barba_pelato_neutro from '../imgs/characters/caucasico_moro_barba_pelato_neutro.png';
import caucasico_moro_barba_pelato_paura from '../imgs/characters/caucasico_moro_barba_pelato_paura.png';
import caucasico_moro_barba_pelato_rabbia from '../imgs/characters/caucasico_moro_barba_pelato_rabbia.png';
import caucasico_moro_barba_pelato_tristezza from '../imgs/characters/caucasico_moro_barba_pelato_tristezza.png';
import caucasico_moro_barba_rabbia from '../imgs/characters/caucasico_moro_barba_rabbia.png';
import caucasico_moro_barba_tristezza from '../imgs/characters/caucasico_moro_barba_tristezza.png';
import caucasico_moro_disgusto from '../imgs/characters/caucasico_moro_disgusto.png';
import caucasico_moro_gioia from '../imgs/characters/caucasico_moro_gioia.png';
import caucasico_moro_neutro from '../imgs/characters/caucasico_moro_neutro.png';
import caucasico_moro_paura from '../imgs/characters/caucasico_moro_paura.png';
import caucasico_moro_pelato_disgusto from '../imgs/characters/caucasico_moro_pelato_disgusto.png';
import caucasico_moro_pelato_gioia from '../imgs/characters/caucasico_moro_pelato_gioia.png';
import caucasico_moro_pelato_neutro from '../imgs/characters/caucasico_moro_pelato_neutro.png';
import caucasico_moro_pelato_paura from '../imgs/characters/caucasico_moro_pelato_paura.png';
import caucasico_moro_pelato_rabbia from '../imgs/characters/caucasico_moro_pelato_rabbia.png';
import caucasico_moro_pelato_tristezza from '../imgs/characters/caucasico_moro_pelato_tristezza.png';
import caucasico_moro_rabbia from '../imgs/characters/caucasico_moro_rabbia.png';
import caucasico_moro_tristezza from '../imgs/characters/caucasico_moro_tristezza.png';
import mamma_asiatica_disgusto from '../imgs/characters/mamma_asiatica_disgusto.png';
import mamma_asiatica_gioia from '../imgs/characters/mamma_asiatica_gioia.png';
import mamma_asiatica_neutro from '../imgs/characters/mamma_asiatica_neutro.png';
import mamma_asiatica_paura from '../imgs/characters/mamma_asiatica_paura.png';
import mamma_asiatica_rabbia from '../imgs/characters/mamma_asiatica_rabbia.png';
import mamma_asiatica_tristezza from '../imgs/characters/mamma_asiatica_tristezza.png';
import mamma_caucasica_bionda_disgusto from '../imgs/characters/mamma_caucasica_bionda_disgusto.png';
import mamma_caucasica_bionda_gioia from '../imgs/characters/mamma_caucasica_bionda_gioia.png';
import mamma_caucasica_bionda_neutro from '../imgs/characters/mamma_caucasica_bionda_neutro.png';
import mamma_caucasica_bionda_paura from '../imgs/characters/mamma_caucasica_bionda_paura.png';
import mamma_caucasica_bionda_rabbia from '../imgs/characters/mamma_caucasica_bionda_rabbia.png';
import mamma_caucasica_bionda_tristezza from '../imgs/characters/mamma_caucasica_bionda_tristezza.png';
import mamma_caucasica_castana_disgusto from '../imgs/characters/mamma_caucasica_castana_disgusto.png';
import mamma_caucasica_castana_gioia from '../imgs/characters/mamma_caucasica_castana_gioia.png';
import mamma_caucasica_castana_neutro from '../imgs/characters/mamma_caucasica_castana_neutro.png';
import mamma_caucasica_castana_paura from '../imgs/characters/mamma_caucasica_castana_paura.png';
import mamma_caucasica_castana_rabbia from '../imgs/characters/mamma_caucasica_castana_rabbia.png';
import mamma_caucasica_castana_tristezza from '../imgs/characters/mamma_caucasica_castana_tristezza.png';
import mamma_caucasica_mora_disgusto from '../imgs/characters/mamma_caucasica_mora_disgusto.png';
import mamma_caucasica_mora_gioia from '../imgs/characters/mamma_caucasica_mora_gioia.png';
import mamma_caucasica_mora_neutro from '../imgs/characters/mamma_caucasica_mora_neutro.png';
import mamma_caucasica_mora_paura from '../imgs/characters/mamma_caucasica_mora_paura.png';
import mamma_caucasica_mora_rabbia from '../imgs/characters/mamma_caucasica_mora_rabbia.png';
import mamma_caucasica_mora_tristezza from '../imgs/characters/mamma_caucasica_mora_tristezza.png';
import mamma_negroide_disgusto from '../imgs/characters/mamma_negroide_disgusto.png';
import mamma_negroide_gioia from '../imgs/characters/mamma_negroide_gioia.png';
import mamma_negroide_neutro from '../imgs/characters/mamma_negroide_neutro.png';
import mamma_negroide_paura from '../imgs/characters/mamma_negroide_paura.png';
import mamma_negroide_rabbia from '../imgs/characters/mamma_negroide_rabbia.png';
import mamma_negroide_tristezza from '../imgs/characters/mamma_negroide_tristezza.png';
import negroide_barba_disgusto from '../imgs/characters/negroide_barba_disgusto.png';
import negroide_barba_gioia from '../imgs/characters/negroide_barba_gioia.png';
import negroide_barba_neutro from '../imgs/characters/negroide_barba_neutro.png';
import negroide_barba_paura from '../imgs/characters/negroide_barba_paura.png';
import negroide_barba_rabbia from '../imgs/characters/negroide_barba_rabbia.png';
import negroide_barba_tristezza from '../imgs/characters/negroide_barba_tristezza.png';
import negroide_disgusto from '../imgs/characters/negroide_disgusto.png';
import negroide_gioia from '../imgs/characters/negroide_gioia.png';
import negroide_neutro from '../imgs/characters/negroide_neutro.png';
import negroide_paura from '../imgs/characters/negroide_paura.png';
import negroide_pelato_disgusto from '../imgs/characters/negroide_pelato_disgusto.png';
import negroide_pelato_gioia from '../imgs/characters/negroide_pelato_gioia.png';
import negroide_pelato_neutro from '../imgs/characters/negroide_pelato_neutro.png';
import negroide_pelato_paura from '../imgs/characters/negroide_pelato_paura.png';
import negroide_pelato_rabbia from '../imgs/characters/negroide_pelato_rabbia.png';
import negroide_pelato_tristezza from '../imgs/characters/negroide_pelato_tristezza.png';
import negroide_rabbia from '../imgs/characters/negroide_rabbia.png';
import negroide_tristezza from '../imgs/characters/negroide_tristezza.png';
import nonna_asiatica_disgusto from '../imgs/characters/nonna_asiatica_disgusto.png';
import nonna_asiatica_gioia from '../imgs/characters/nonna_asiatica_gioia.png';
import nonna_asiatica_neutro from '../imgs/characters/nonna_asiatica_neutro.png';
import nonna_asiatica_paura from '../imgs/characters/nonna_asiatica_paura.png';
import nonna_asiatica_rabbia from '../imgs/characters/nonna_asiatica_rabbia.png';
import nonna_asiatica_tristezza from '../imgs/characters/nonna_asiatica_tristezza.png';
import nonna_caucasica_disgusto from '../imgs/characters/nonna_caucasica_disgusto.png';
import nonna_caucasica_gioia from '../imgs/characters/nonna_caucasica_gioia.png';
import nonna_caucasica_neutro from '../imgs/characters/nonna_caucasica_neutro.png';
import nonna_caucasica_paura from '../imgs/characters/nonna_caucasica_paura.png';
import nonna_caucasica_rabbia from '../imgs/characters/nonna_caucasica_rabbia.png';
import nonna_caucasica_tristezza from '../imgs/characters/nonna_caucasica_tristezza.png';
import nonna_negroide_disgusto from '../imgs/characters/nonna_negroide_disgusto.png';
import nonna_negroide_gioia from '../imgs/characters/nonna_negroide_gioia.png';
import nonna_negroide_neutro from '../imgs/characters/nonna_negroide_neutro.png';
import nonna_negroide_paura from '../imgs/characters/nonna_negroide_paura.png';
import nonna_negroide_rabbia from '../imgs/characters/nonna_negroide_rabbia.png';
import nonna_negroide_tristezza from '../imgs/characters/nonna_negroide_tristezza.png';
import nonno_asiatico_disgusto from '../imgs/characters/nonno_asiatico_disgusto.png';
import nonno_asiatico_gioia from '../imgs/characters/nonno_asiatico_gioia.png';
import nonno_asiatico_neutro from '../imgs/characters/nonno_asiatico_neutro.png';
import nonno_asiatico_paura from '../imgs/characters/nonno_asiatico_paura.png';
import nonno_asiatico_rabbia from '../imgs/characters/nonno_asiatico_rabbia.png';
import nonno_asiatico_tristezza from '../imgs/characters/nonno_asiatico_tristezza.png';
import nonno_caucasico_disgusto from '../imgs/characters/nonno_caucasico_disgusto.png';
import nonno_caucasico_gioia from '../imgs/characters/nonno_caucasico_gioia.png';
import nonno_caucasico_neutro from '../imgs/characters/nonno_caucasico_neutro.png';
import nonno_caucasico_paura from '../imgs/characters/nonno_caucasico_paura.png';
import nonno_caucasico_rabbia from '../imgs/characters/nonno_caucasico_rabbia.png';
import nonno_caucasico_tristezza from '../imgs/characters/nonno_caucasico_tristezza.png';
import nonno_negroide_disgusto from '../imgs/characters/nonno_negroide_disgusto.png';
import nonno_negroide_gioia from '../imgs/characters/nonno_negroide_gioia.png';
import nonno_negroide_neutro from '../imgs/characters/nonno_negroide_neutro.png';
import nonno_negroide_paura from '../imgs/characters/nonno_negroide_paura.png';
import nonno_negroide_rabbia from '../imgs/characters/nonno_negroide_rabbia.png';
import nonno_negroide_tristezza from '../imgs/characters/nonno_negroide_tristezza.png';
import ragazza_asiatica_disgusto from '../imgs/characters/ragazza_asiatica_disgusto.png';
import ragazza_asiatica_gioia from '../imgs/characters/ragazza_asiatica_gioia.png';
import ragazza_asiatica_neutro from '../imgs/characters/ragazza_asiatica_neutro.png';
import ragazza_asiatica_paura from '../imgs/characters/ragazza_asiatica_paura.png';
import ragazza_asiatica_rabbia from '../imgs/characters/ragazza_asiatica_rabbia.png';
import ragazza_asiatica_tristezza from '../imgs/characters/ragazza_asiatica_tristezza.png';
import ragazza_caucasica_bionda_disgusto from '../imgs/characters/ragazza_caucasica_bionda_disgusto.png';
import ragazza_caucasica_bionda_gioia from '../imgs/characters/ragazza_caucasica_bionda_gioia.png';
import ragazza_caucasica_bionda_neutro from '../imgs/characters/ragazza_caucasica_bionda_neutro.png';
import ragazza_caucasica_bionda_paura from '../imgs/characters/ragazza_caucasica_bionda_paura.png';
import ragazza_caucasica_bionda_rabbia from '../imgs/characters/ragazza_caucasica_bionda_rabbia.png';
import ragazza_caucasica_bionda_tristezza from '../imgs/characters/ragazza_caucasica_bionda_tristezza.png';
import ragazza_caucasica_castana_disgusto from '../imgs/characters/ragazza_caucasica_castana_disgusto.png';
import ragazza_caucasica_castana_gioia from '../imgs/characters/ragazza_caucasica_castana_gioia.png';
import ragazza_caucasica_castana_neutro from '../imgs/characters/ragazza_caucasica_castana_neutro.png';
import ragazza_caucasica_castana_paura from '../imgs/characters/ragazza_caucasica_castana_paura.png';
import ragazza_caucasica_castana_rabbia from '../imgs/characters/ragazza_caucasica_castana_rabbia.png';
import ragazza_caucasica_castana_tristezza from '../imgs/characters/ragazza_caucasica_castana_tristezza.png';
import ragazza_caucasica_mora_disgusto from '../imgs/characters/ragazza_caucasica_mora_disgusto.png';
import ragazza_caucasica_mora_gioia from '../imgs/characters/ragazza_caucasica_mora_gioia.png';
import ragazza_caucasica_mora_neutro from '../imgs/characters/ragazza_caucasica_mora_neutro.png';
import ragazza_caucasica_mora_paura from '../imgs/characters/ragazza_caucasica_mora_paura.png';
import ragazza_caucasica_mora_rabbia from '../imgs/characters/ragazza_caucasica_mora_rabbia.png';
import ragazza_caucasica_mora_tristezza from '../imgs/characters/ragazza_caucasica_mora_tristezza.png';
import ragazza_negreoide_disgusto from '../imgs/characters/ragazza_negreoide_disgusto.png';
import ragazza_negreoide_gioia from '../imgs/characters/ragazza_negreoide_gioia.png';
import ragazza_negreoide_neutro from '../imgs/characters/ragazza_negreoide_neutro.png';
import ragazza_negreoide_paura from '../imgs/characters/ragazza_negreoide_paura.png';
import ragazza_negreoide_rabbia from '../imgs/characters/ragazza_negreoide_rabbia.png';
import ragazza_negreoide_tristezza from '../imgs/characters/ragazza_negreoide_tristezza.png';
import ragazzo_asiatico_disgusto from '../imgs/characters/ragazzo_asiatico_disgusto.png';
import ragazzo_asiatico_gioia from '../imgs/characters/ragazzo_asiatico_gioia.png';
import ragazzo_asiatico_neutro from '../imgs/characters/ragazzo_asiatico_neutro.png';
import ragazzo_asiatico_paura from '../imgs/characters/ragazzo_asiatico_paura.png';
import ragazzo_asiatico_rabbia from '../imgs/characters/ragazzo_asiatico_rabbia.png';
import ragazzo_asiatico_tristezza from '../imgs/characters/ragazzo_asiatico_tristezza.png';
import ragazzo_caucasico_biondo_disgusto from '../imgs/characters/ragazzo_caucasico_biondo_disgusto.png';
import ragazzo_caucasico_biondo_gioia from '../imgs/characters/ragazzo_caucasico_biondo_gioia.png';
import ragazzo_caucasico_biondo_neutro from '../imgs/characters/ragazzo_caucasico_biondo_neutro.png';
import ragazzo_caucasico_biondo_paura from '../imgs/characters/ragazzo_caucasico_biondo_paura.png';
import ragazzo_caucasico_biondo_rabbia from '../imgs/characters/ragazzo_caucasico_biondo_rabbia.png';
import ragazzo_caucasico_biondo_tristezza from '../imgs/characters/ragazzo_caucasico_biondo_tristezza.png';
import ragazzo_caucasico_disgusto from '../imgs/characters/ragazzo_caucasico_disgusto.png';
import ragazzo_caucasico_gioia from '../imgs/characters/ragazzo_caucasico_gioia.png';
import ragazzo_caucasico_moro_disgusto from '../imgs/characters/ragazzo_caucasico_moro_disgusto.png';
import ragazzo_caucasico_moro_gioia from '../imgs/characters/ragazzo_caucasico_moro_gioia.png';
import ragazzo_caucasico_moro_neutro from '../imgs/characters/ragazzo_caucasico_moro_neutro.png';
import ragazzo_caucasico_moro_paura from '../imgs/characters/ragazzo_caucasico_moro_paura.png';
import ragazzo_caucasico_moro_rabbia from '../imgs/characters/ragazzo_caucasico_moro_rabbia.png';
import ragazzo_caucasico_moro_tristezza from '../imgs/characters/ragazzo_caucasico_moro_tristezza.png';
import ragazzo_caucasico_neutro from '../imgs/characters/ragazzo_caucasico_neutro.png';
import ragazzo_caucasico_paura from '../imgs/characters/ragazzo_caucasico_paura.png';
import ragazzo_caucasico_rabbia from '../imgs/characters/ragazzo_caucasico_rabbia.png';
import ragazzo_caucasico_tristezza from '../imgs/characters/ragazzo_caucasico_tristezza.png';
import ragazzo_negroide_disgusto from '../imgs/characters/ragazzo_negroide_disgusto.png';
import ragazzo_negroide_gioia from '../imgs/characters/ragazzo_negroide_gioia.png';
import ragazzo_negroide_neutro from '../imgs/characters/ragazzo_negroide_neutro.png';
import ragazzo_negroide_paura from '../imgs/characters/ragazzo_negroide_paura.png';
import ragazzo_negroide_rabbia from '../imgs/characters/ragazzo_negroide_rabbia.png';
import ragazzo_negroide_tristezza from '../imgs/characters/ragazzo_negroide_tristezza.png';

export const CharacterImages = {
    'asiatico_disgusto': asiatico_disgusto,
    'asiatico_gioia': asiatico_gioia,
    'asiatico_neutro': asiatico_neutro,
    'asiatico_paura': asiatico_paura,
    'asiatico_pelato_disgusto': asiatico_pelato_disgusto,
    'asiatico_pelato_gioia': asiatico_pelato_gioia,
    'asiatico_pelato_neutro': asiatico_pelato_neutro,
    'asiatico_pelato_paura': asiatico_pelato_paura,
    'asiatico_pelato_rabbia': asiatico_pelato_rabbia,
    'asiatico_pelato_tristezza': asiatico_pelato_tristezza,
    'asiatico_rabbia': asiatico_rabbia,
    'asiatico_tristezza': asiatico_tristezza,
    'baby_asiatico_neutro': baby_asiatico_neutro,
    'baby_asiatico_tristezza': baby_asiatico_tristezza,
    'baby_caucasico_biondo_neutro': baby_caucasico_biondo_neutro,
    'baby_caucasico_biondo_tristezza': baby_caucasico_biondo_tristezza,
    'baby_caucasico_castano_neutro': baby_caucasico_castano_neutro,
    'baby_caucasico_castano_tristezza': baby_caucasico_castano_tristezza,
    'baby_caucasico_moro_neutro': baby_caucasico_moro_neutro,
    'baby_caucasico_moro_tristezza': baby_caucasico_moro_tristezza,
    'baby_negroide_neutro': baby_negroide_neutro,
    'baby_negroide_tristezza': baby_negroide_tristezza,
    'bambina_asiatica_disgusto': bambina_asiatica_disgusto,
    'bambina_asiatica_gioia': bambina_asiatica_gioia,
    'bambina_asiatica_neutro': bambina_asiatica_neutro,
    'bambina_asiatica_paura': bambina_asiatica_paura,
    'bambina_asiatica_rabbia': bambina_asiatica_rabbia,
    'bambina_asiatica_tristezza': bambina_asiatica_tristezza,
    'bambina_caucasica_bionda_disgusto': bambina_caucasica_bionda_disgusto,
    'bambina_caucasica_bionda_gioia': bambina_caucasica_bionda_gioia,
    'bambina_caucasica_bionda_neutro': bambina_caucasica_bionda_neutro,
    'bambina_caucasica_bionda_paura': bambina_caucasica_bionda_paura,
    'bambina_caucasica_bionda_rabbia': bambina_caucasica_bionda_rabbia,
    'bambina_caucasica_bionda_tristezza': bambina_caucasica_bionda_tristezza,
    'bambina_caucasica_castana_disgusto': bambina_caucasica_castana_disgusto,
    'bambina_caucasica_castana_gioia': bambina_caucasica_castana_gioia,
    'bambina_caucasica_castana_neutro': bambina_caucasica_castana_neutro,
    'bambina_caucasica_castana_paura': bambina_caucasica_castana_paura,
    'bambina_caucasica_castana_rabbia': bambina_caucasica_castana_rabbia,
    'bambina_caucasica_castana_tristezza': bambina_caucasica_castana_tristezza,
    'bambina_caucasica_mora_disgusto': bambina_caucasica_mora_disgusto,
    'bambina_caucasica_mora_gioia': bambina_caucasica_mora_gioia,
    'bambina_caucasica_mora_neutro': bambina_caucasica_mora_neutro,
    'bambina_caucasica_mora_paura': bambina_caucasica_mora_paura,
    'bambina_caucasica_mora_rabbia': bambina_caucasica_mora_rabbia,
    'bambina_caucasica_mora_tristezza': bambina_caucasica_mora_tristezza,
    'bambina_negroide_disgusto': bambina_negroide_disgusto,
    'bambina_negroide_gioia': bambina_negroide_gioia,
    'bambina_negroide_neutro': bambina_negroide_neutro,
    'bambina_negroide_paura': bambina_negroide_paura,
    'bambina_negroide_rabbia': bambina_negroide_rabbia,
    'bambina_negroide_tristezza': bambina_negroide_tristezza,
    'bambino_asiatico_disgusto': bambino_asiatico_disgusto,
    'bambino_asiatico_gioia': bambino_asiatico_gioia,
    'bambino_asiatico_neutro': bambino_asiatico_neutro,
    'bambino_asiatico_paura': bambino_asiatico_paura,
    'bambino_asiatico_rabbia': bambino_asiatico_rabbia,
    'bambino_asiatico_tristezza': bambino_asiatico_tristezza,
    'bambino_caucasico_biondo_disgusto': bambino_caucasico_biondo_disgusto,
    'bambino_caucasico_biondo_gioia': bambino_caucasico_biondo_gioia,
    'bambino_caucasico_biondo_neutro': bambino_caucasico_biondo_neutro,
    'bambino_caucasico_biondo_paura': bambino_caucasico_biondo_paura,
    'bambino_caucasico_biondo_rabbia': bambino_caucasico_biondo_rabbia,
    'bambino_caucasico_biondo_tristezza': bambino_caucasico_biondo_tristezza,
    'bambino_caucasico_castano_disgusto': bambino_caucasico_castano_disgusto,
    'bambino_caucasico_castano_gioia': bambino_caucasico_castano_gioia,
    'bambino_caucasico_castano_neutro': bambino_caucasico_castano_neutro,
    'bambino_caucasico_castano_paura': bambino_caucasico_castano_paura,
    'bambino_caucasico_castano_rabbia': bambino_caucasico_castano_rabbia,
    'bambino_caucasico_castano_tristezza': bambino_caucasico_castano_tristezza,
    'bambino_caucasico_moro_disgusto': bambino_caucasico_moro_disgusto,
    'bambino_caucasico_moro_gioia': bambino_caucasico_moro_gioia,
    'bambino_caucasico_moro_neutro': bambino_caucasico_moro_neutro,
    'bambino_caucasico_moro_paura': bambino_caucasico_moro_paura,
    'bambino_caucasico_moro_rabbia': bambino_caucasico_moro_rabbia,
    'bambino_caucasico_moro_tristezza': bambino_caucasico_moro_tristezza,
    'bambino_negroide_disgusto': bambino_negroide_disgusto,
    'bambino_negroide_gioia': bambino_negroide_gioia,
    'bambino_negroide_neutro': bambino_negroide_neutro,
    'bambino_negroide_paura': bambino_negroide_paura,
    'bambino_negroide_rabbia': bambino_negroide_rabbia,
    'bambino_negroide_tristezza': bambino_negroide_tristezza,
    'caucasico_biondo_barba_disgusto': caucasico_biondo_barba_disgusto,
    'caucasico_biondo_barba_gioia': caucasico_biondo_barba_gioia,
    'caucasico_biondo_barba_neutro': caucasico_biondo_barba_neutro,
    'caucasico_biondo_barba_paura': caucasico_biondo_barba_paura,
    'caucasico_biondo_barba_rabbia': caucasico_biondo_barba_rabbia,
    'caucasico_biondo_barba_tristezza': caucasico_biondo_barba_tristezza,
    'caucasico_biondo_disgusto': caucasico_biondo_disgusto,
    'caucasico_biondo_gioia': caucasico_biondo_gioia,
    'caucasico_biondo_neutro': caucasico_biondo_neutro,
    'caucasico_biondo_paura': caucasico_biondo_paura,
    'caucasico_biondo_pelato_barba_disgusto': caucasico_biondo_pelato_barba_disgusto,
    'caucasico_biondo_pelato_barba_gioia': caucasico_biondo_pelato_barba_gioia,
    'caucasico_biondo_pelato_barba_neutro': caucasico_biondo_pelato_barba_neutro,
    'caucasico_biondo_pelato_barba_paura': caucasico_biondo_pelato_barba_paura,
    'caucasico_biondo_pelato_barba_rabbia': caucasico_biondo_pelato_barba_rabbia,
    'caucasico_biondo_pelato_barba_tristezza': caucasico_biondo_pelato_barba_tristezza,
    'caucasico_biondo_pelato_disgusto': caucasico_biondo_pelato_disgusto,
    'caucasico_biondo_pelato_gioia': caucasico_biondo_pelato_gioia,
    'caucasico_biondo_pelato_neutro': caucasico_biondo_pelato_neutro,
    'caucasico_biondo_pelato_paura': caucasico_biondo_pelato_paura,
    'caucasico_biondo_pelato_rabbia': caucasico_biondo_pelato_rabbia,
    'caucasico_biondo_pelato_tristezza': caucasico_biondo_pelato_tristezza,
    'caucasico_biondo_rabbia': caucasico_biondo_rabbia,
    'caucasico_biondo_tristezza': caucasico_biondo_tristezza,
    'caucasico_castano_barba_disgusto': caucasico_castano_barba_disgusto,
    'caucasico_castano_barba_gioia': caucasico_castano_barba_gioia,
    'caucasico_castano_barba_neutro': caucasico_castano_barba_neutro,
    'caucasico_castano_barba_paura': caucasico_castano_barba_paura,
    'caucasico_castano_barba_rabbia': caucasico_castano_barba_rabbia,
    'caucasico_castano_barba_tristezza': caucasico_castano_barba_tristezza,
    'caucasico_castano_disgusto': caucasico_castano_disgusto,
    'caucasico_castano_gioia': caucasico_castano_gioia,
    'caucasico_castano_neutro': caucasico_castano_neutro,
    'caucasico_castano_paura': caucasico_castano_paura,
    'caucasico_castano_pelato_barba_disgusto': caucasico_castano_pelato_barba_disgusto,
    'caucasico_castano_pelato_barba_gioia': caucasico_castano_pelato_barba_gioia,
    'caucasico_castano_pelato_barba_neutro': caucasico_castano_pelato_barba_neutro,
    'caucasico_castano_pelato_barba_paura': caucasico_castano_pelato_barba_paura,
    'caucasico_castano_pelato_barba_rabbia': caucasico_castano_pelato_barba_rabbia,
    'caucasico_castano_pelato_barba_tristezza': caucasico_castano_pelato_barba_tristezza,
    'caucasico_castano_pelato_disgusto': caucasico_castano_pelato_disgusto,
    'caucasico_castano_pelato_gioia': caucasico_castano_pelato_gioia,
    'caucasico_castano_pelato_neutro': caucasico_castano_pelato_neutro,
    'caucasico_castano_pelato_paura': caucasico_castano_pelato_paura,
    'caucasico_castano_pelato_rabbia': caucasico_castano_pelato_rabbia,
    'caucasico_castano_pelato_tristezza': caucasico_castano_pelato_tristezza,
    'caucasico_castano_rabbia': caucasico_castano_rabbia,
    'caucasico_castano_tristezza': caucasico_castano_tristezza,
    'caucasico_moro_barba_disgusto': caucasico_moro_barba_disgusto,
    'caucasico_moro_barba_gioia': caucasico_moro_barba_gioia,
    'caucasico_moro_barba_neutro': caucasico_moro_barba_neutro,
    'caucasico_moro_barba_paura': caucasico_moro_barba_paura,
    'caucasico_moro_barba_pelato_disgusto': caucasico_moro_barba_pelato_disgusto,
    'caucasico_moro_barba_pelato_gioia': caucasico_moro_barba_pelato_gioia,
    'caucasico_moro_barba_pelato_neutro': caucasico_moro_barba_pelato_neutro,
    'caucasico_moro_barba_pelato_paura': caucasico_moro_barba_pelato_paura,
    'caucasico_moro_barba_pelato_rabbia': caucasico_moro_barba_pelato_rabbia,
    'caucasico_moro_barba_pelato_tristezza': caucasico_moro_barba_pelato_tristezza,
    'caucasico_moro_barba_rabbia': caucasico_moro_barba_rabbia,
    'caucasico_moro_barba_tristezza': caucasico_moro_barba_tristezza,
    'caucasico_moro_disgusto': caucasico_moro_disgusto,
    'caucasico_moro_gioia': caucasico_moro_gioia,
    'caucasico_moro_neutro': caucasico_moro_neutro,
    'caucasico_moro_paura': caucasico_moro_paura,
    'caucasico_moro_pelato_disgusto': caucasico_moro_pelato_disgusto,
    'caucasico_moro_pelato_gioia': caucasico_moro_pelato_gioia,
    'caucasico_moro_pelato_neutro': caucasico_moro_pelato_neutro,
    'caucasico_moro_pelato_paura': caucasico_moro_pelato_paura,
    'caucasico_moro_pelato_rabbia': caucasico_moro_pelato_rabbia,
    'caucasico_moro_pelato_tristezza': caucasico_moro_pelato_tristezza,
    'caucasico_moro_rabbia': caucasico_moro_rabbia,
    'caucasico_moro_tristezza': caucasico_moro_tristezza,
    'mamma_asiatica_disgusto': mamma_asiatica_disgusto,
    'mamma_asiatica_gioia': mamma_asiatica_gioia,
    'mamma_asiatica_neutro': mamma_asiatica_neutro,
    'mamma_asiatica_paura': mamma_asiatica_paura,
    'mamma_asiatica_rabbia': mamma_asiatica_rabbia,
    'mamma_asiatica_tristezza': mamma_asiatica_tristezza,
    'mamma_caucasica_bionda_disgusto': mamma_caucasica_bionda_disgusto,
    'mamma_caucasica_bionda_gioia': mamma_caucasica_bionda_gioia,
    'mamma_caucasica_bionda_neutro': mamma_caucasica_bionda_neutro,
    'mamma_caucasica_bionda_paura': mamma_caucasica_bionda_paura,
    'mamma_caucasica_bionda_rabbia': mamma_caucasica_bionda_rabbia,
    'mamma_caucasica_bionda_tristezza': mamma_caucasica_bionda_tristezza,
    'mamma_caucasica_castana_disgusto': mamma_caucasica_castana_disgusto,
    'mamma_caucasica_castana_gioia': mamma_caucasica_castana_gioia,
    'mamma_caucasica_castana_neutro': mamma_caucasica_castana_neutro,
    'mamma_caucasica_castana_paura': mamma_caucasica_castana_paura,
    'mamma_caucasica_castana_rabbia': mamma_caucasica_castana_rabbia,
    'mamma_caucasica_castana_tristezza': mamma_caucasica_castana_tristezza,
    'mamma_caucasica_mora_disgusto': mamma_caucasica_mora_disgusto,
    'mamma_caucasica_mora_gioia': mamma_caucasica_mora_gioia,
    'mamma_caucasica_mora_neutro': mamma_caucasica_mora_neutro,
    'mamma_caucasica_mora_paura': mamma_caucasica_mora_paura,
    'mamma_caucasica_mora_rabbia': mamma_caucasica_mora_rabbia,
    'mamma_caucasica_mora_tristezza': mamma_caucasica_mora_tristezza,
    'mamma_negroide_disgusto': mamma_negroide_disgusto,
    'mamma_negroide_gioia': mamma_negroide_gioia,
    'mamma_negroide_neutro': mamma_negroide_neutro,
    'mamma_negroide_paura': mamma_negroide_paura,
    'mamma_negroide_rabbia': mamma_negroide_rabbia,
    'mamma_negroide_tristezza': mamma_negroide_tristezza,
    'negroide_barba_disgusto': negroide_barba_disgusto,
    'negroide_barba_gioia': negroide_barba_gioia,
    'negroide_barba_neutro': negroide_barba_neutro,
    'negroide_barba_paura': negroide_barba_paura,
    'negroide_barba_rabbia': negroide_barba_rabbia,
    'negroide_barba_tristezza': negroide_barba_tristezza,
    'negroide_disgusto': negroide_disgusto,
    'negroide_gioia': negroide_gioia,
    'negroide_neutro': negroide_neutro,
    'negroide_paura': negroide_paura,
    'negroide_pelato_disgusto': negroide_pelato_disgusto,
    'negroide_pelato_gioia': negroide_pelato_gioia,
    'negroide_pelato_neutro': negroide_pelato_neutro,
    'negroide_pelato_paura': negroide_pelato_paura,
    'negroide_pelato_rabbia': negroide_pelato_rabbia,
    'negroide_pelato_tristezza': negroide_pelato_tristezza,
    'negroide_rabbia': negroide_rabbia,
    'negroide_tristezza': negroide_tristezza,
    'nonna_asiatica_disgusto': nonna_asiatica_disgusto,
    'nonna_asiatica_gioia': nonna_asiatica_gioia,
    'nonna_asiatica_neutro': nonna_asiatica_neutro,
    'nonna_asiatica_paura': nonna_asiatica_paura,
    'nonna_asiatica_rabbia': nonna_asiatica_rabbia,
    'nonna_asiatica_tristezza': nonna_asiatica_tristezza,
    'nonna_caucasica_disgusto': nonna_caucasica_disgusto,
    'nonna_caucasica_gioia': nonna_caucasica_gioia,
    'nonna_caucasica_neutro': nonna_caucasica_neutro,
    'nonna_caucasica_paura': nonna_caucasica_paura,
    'nonna_caucasica_rabbia': nonna_caucasica_rabbia,
    'nonna_caucasica_tristezza': nonna_caucasica_tristezza,
    'nonna_negroide_disgusto': nonna_negroide_disgusto,
    'nonna_negroide_gioia': nonna_negroide_gioia,
    'nonna_negroide_neutro': nonna_negroide_neutro,
    'nonna_negroide_paura': nonna_negroide_paura,
    'nonna_negroide_rabbia': nonna_negroide_rabbia,
    'nonna_negroide_tristezza': nonna_negroide_tristezza,
    'nonno_asiatico_disgusto': nonno_asiatico_disgusto,
    'nonno_asiatico_gioia': nonno_asiatico_gioia,
    'nonno_asiatico_neutro': nonno_asiatico_neutro,
    'nonno_asiatico_paura': nonno_asiatico_paura,
    'nonno_asiatico_rabbia': nonno_asiatico_rabbia,
    'nonno_asiatico_tristezza': nonno_asiatico_tristezza,
    'nonno_caucasico_disgusto': nonno_caucasico_disgusto,
    'nonno_caucasico_gioia': nonno_caucasico_gioia,
    'nonno_caucasico_neutro': nonno_caucasico_neutro,
    'nonno_caucasico_paura': nonno_caucasico_paura,
    'nonno_caucasico_rabbia': nonno_caucasico_rabbia,
    'nonno_caucasico_tristezza': nonno_caucasico_tristezza,
    'nonno_negroide_disgusto': nonno_negroide_disgusto,
    'nonno_negroide_gioia': nonno_negroide_gioia,
    'nonno_negroide_neutro': nonno_negroide_neutro,
    'nonno_negroide_paura': nonno_negroide_paura,
    'nonno_negroide_rabbia': nonno_negroide_rabbia,
    'nonno_negroide_tristezza': nonno_negroide_tristezza,
    'ragazza_asiatica_disgusto': ragazza_asiatica_disgusto,
    'ragazza_asiatica_gioia': ragazza_asiatica_gioia,
    'ragazza_asiatica_neutro': ragazza_asiatica_neutro,
    'ragazza_asiatica_paura': ragazza_asiatica_paura,
    'ragazza_asiatica_rabbia': ragazza_asiatica_rabbia,
    'ragazza_asiatica_tristezza': ragazza_asiatica_tristezza,
    'ragazza_caucasica_bionda_disgusto': ragazza_caucasica_bionda_disgusto,
    'ragazza_caucasica_bionda_gioia': ragazza_caucasica_bionda_gioia,
    'ragazza_caucasica_bionda_neutro': ragazza_caucasica_bionda_neutro,
    'ragazza_caucasica_bionda_paura': ragazza_caucasica_bionda_paura,
    'ragazza_caucasica_bionda_rabbia': ragazza_caucasica_bionda_rabbia,
    'ragazza_caucasica_bionda_tristezza': ragazza_caucasica_bionda_tristezza,
    'ragazza_caucasica_castana_disgusto': ragazza_caucasica_castana_disgusto,
    'ragazza_caucasica_castana_gioia': ragazza_caucasica_castana_gioia,
    'ragazza_caucasica_castana_neutro': ragazza_caucasica_castana_neutro,
    'ragazza_caucasica_castana_paura': ragazza_caucasica_castana_paura,
    'ragazza_caucasica_castana_rabbia': ragazza_caucasica_castana_rabbia,
    'ragazza_caucasica_castana_tristezza': ragazza_caucasica_castana_tristezza,
    'ragazza_caucasica_mora_disgusto': ragazza_caucasica_mora_disgusto,
    'ragazza_caucasica_mora_gioia': ragazza_caucasica_mora_gioia,
    'ragazza_caucasica_mora_neutro': ragazza_caucasica_mora_neutro,
    'ragazza_caucasica_mora_paura': ragazza_caucasica_mora_paura,
    'ragazza_caucasica_mora_rabbia': ragazza_caucasica_mora_rabbia,
    'ragazza_caucasica_mora_tristezza': ragazza_caucasica_mora_tristezza,
    'ragazza_negreoide_disgusto': ragazza_negreoide_disgusto,
    'ragazza_negreoide_gioia': ragazza_negreoide_gioia,
    'ragazza_negreoide_neutro': ragazza_negreoide_neutro,
    'ragazza_negreoide_paura': ragazza_negreoide_paura,
    'ragazza_negreoide_rabbia': ragazza_negreoide_rabbia,
    'ragazza_negreoide_tristezza': ragazza_negreoide_tristezza,
    'ragazzo_asiatico_disgusto': ragazzo_asiatico_disgusto,
    'ragazzo_asiatico_gioia': ragazzo_asiatico_gioia,
    'ragazzo_asiatico_neutro': ragazzo_asiatico_neutro,
    'ragazzo_asiatico_paura': ragazzo_asiatico_paura,
    'ragazzo_asiatico_rabbia': ragazzo_asiatico_rabbia,
    'ragazzo_asiatico_tristezza': ragazzo_asiatico_tristezza,
    'ragazzo_caucasico_biondo_disgusto': ragazzo_caucasico_biondo_disgusto,
    'ragazzo_caucasico_biondo_gioia': ragazzo_caucasico_biondo_gioia,
    'ragazzo_caucasico_biondo_neutro': ragazzo_caucasico_biondo_neutro,
    'ragazzo_caucasico_biondo_paura': ragazzo_caucasico_biondo_paura,
    'ragazzo_caucasico_biondo_rabbia': ragazzo_caucasico_biondo_rabbia,
    'ragazzo_caucasico_biondo_tristezza': ragazzo_caucasico_biondo_tristezza,
    'ragazzo_caucasico_disgusto': ragazzo_caucasico_disgusto,
    'ragazzo_caucasico_gioia': ragazzo_caucasico_gioia,
    'ragazzo_caucasico_moro_disgusto': ragazzo_caucasico_moro_disgusto,
    'ragazzo_caucasico_moro_gioia': ragazzo_caucasico_moro_gioia,
    'ragazzo_caucasico_moro_neutro': ragazzo_caucasico_moro_neutro,
    'ragazzo_caucasico_moro_paura': ragazzo_caucasico_moro_paura,
    'ragazzo_caucasico_moro_rabbia': ragazzo_caucasico_moro_rabbia,
    'ragazzo_caucasico_moro_tristezza': ragazzo_caucasico_moro_tristezza,
    'ragazzo_caucasico_neutro': ragazzo_caucasico_neutro,
    'ragazzo_caucasico_paura': ragazzo_caucasico_paura,
    'ragazzo_caucasico_rabbia': ragazzo_caucasico_rabbia,
    'ragazzo_caucasico_tristezza': ragazzo_caucasico_tristezza,
    'ragazzo_negroide_disgusto': ragazzo_negroide_disgusto,
    'ragazzo_negroide_gioia': ragazzo_negroide_gioia,
    'ragazzo_negroide_neutro': ragazzo_negroide_neutro,
    'ragazzo_negroide_paura': ragazzo_negroide_paura,
    'ragazzo_negroide_rabbia': ragazzo_negroide_rabbia,
    'ragazzo_negroide_tristezza': ragazzo_negroide_tristezza,
};

export default CharacterImages;