import React from 'react';
import { connect } from 'react-redux'

import imgContatto from '../imgs/contatto.png';

import './Footer.scss';

const Footer = props => {
  const { left, right } = props;
  //
  return (
    <div className="footer">
      <div className="row">
        <div className="col-6">
          {left ? left : <img src={imgContatto} alt="Contatto" width="290" style={{ marginTop: '-15px', marginBottom: '-20px' }} />}
        </div>
        <div className="col-6 text-right">
          {right ? right : null}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({})

export default connect(
  mapStateToProps
)(Footer)