import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import BootstrapButton from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import Bootbox  from  'bootbox-react';

import Button from '../components/Button';
import Footer from '../components/Footer';

import { setSurveyData, setTitle } from '../store/actions';

const EditConfiguration = props => {
  const history = useHistory();
  const { match: { params: { id } } } = props;

  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState('');
  const [values, setValues] = useState(props.data || {
    parent1_firstname: '',
    parent1_lastname: '',
    parent1_birthdate: '',
    parent1_email: '',
    parent1_phone: '',
    parent2_firstname: '',
    parent2_lastname: '',
    parent2_birthdate: '',
    parent2_email: '',
    parent2_phone: '',
    child_firstname: '',
    child_lastname: '',
    child_birthdate: '',
    notes: '',
    privacy: false
  });

  const updateValues = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  const { setSurveyData, setTitle } = props;
  setTimeout(() => setTitle('THINK UP FAMILY LIFE'), 100);

  const next = () => {
    if (!(
      values.parent1_firstname &&
      values.parent1_lastname &&
      values.parent1_birthdate &&
      values.parent1_email &&
      values.parent1_phone &&
      values.child_firstname &&
      values.child_lastname &&
      values.child_birthdate &&
      values.privacy
    )) {
      setAlert('Attenzione, compila tutti i campi obbligatori prima di proseguire');
    } else {
      setSurveyData(values);
      history.push("/" + id + "/background")
    }
  };

  return (
    <>
      <div className="container-padding">
        <h5 className="h5">INSERISCI I DATI</h5>
        <Form>
          <div className="row">
            <div className="col-4">
              <h5 className="h5 mt30 mb15">GENITORE 1/TUTORE</h5>
              <Form.Group controlId="parent1_firstname">
                <Form.Control type="text" name="parent1_firstname" placeholder="* Nome..." defaultValue={values.parent1_firstname} onChange={updateValues} />
              </Form.Group>
              <Form.Group controlId="parent1_lastname">
                <Form.Control type="text" name="parent1_lastname" placeholder="* Cognome..." defaultValue={values.parent1_lastname} onChange={updateValues} />
              </Form.Group>
              <Form.Group controlId="parent1_birthdate">
                <Form.Control type="date" name="parent1_birthdate" placeholder="* Data di nascita..." defaultValue={values.parent1_birthdate} onChange={updateValues} />
              </Form.Group>
              <Form.Group controlId="parent1_email">
                <Form.Control type="email" name="parent1_email" placeholder="* Email..." defaultValue={values.parent1_email} onChange={updateValues} />
              </Form.Group>
              <Form.Group controlId="parent1_phone">
                <Form.Control type="text" name="parent1_phone" placeholder="* Telefono..." defaultValue={values.parent1_phone} onChange={updateValues} />
              </Form.Group>
            </div>
            <div className="col-4">
              <h5 className="h5 mt30 mb15">GENITORE 2/TUTORE</h5>
              <Form.Group controlId="parent2_firstname">
                <Form.Control type="text" name="parent2_firstname" placeholder="Nome..." defaultValue={values.parent2_firstname} onChange={updateValues} />
              </Form.Group>
              <Form.Group controlId="parent2_lastname">
                <Form.Control type="text" name="parent2_lastname" placeholder="Cognome..." defaultValue={values.parent2_lastname} onChange={updateValues} />
              </Form.Group>
              <Form.Group controlId="parent2_birthdate">
                <Form.Control type="date" name="parent2_birthdate" placeholder="Data di nascita..." defaultValue={values.parent2_birthdate} onChange={updateValues} />
              </Form.Group>
              <Form.Group controlId="parent2_email">
                <Form.Control type="email" name="parent2_email" placeholder="Email..." defaultValue={values.parent2_email} onChange={updateValues} />
              </Form.Group>
              <Form.Group controlId="parent2_phone">
                <Form.Control type="text" name="parent2_phone" placeholder="Telefono..." defaultValue={values.parent2_phone} onChange={updateValues} />
              </Form.Group>
            </div>
            <div className="col-4">
              <h5 className="h5 mt30 mb15">MINORE</h5>
              <Form.Group controlId="child_firstname">
                <Form.Control type="text" name="child_firstname" placeholder="* Nome..." defaultValue={values.child_firstname} onChange={updateValues} />
              </Form.Group>
              <Form.Group controlId="child_lastname">
                <Form.Control type="text" name="child_lastname" placeholder="* Cognome..." defaultValue={values.child_lastname} onChange={updateValues} />
              </Form.Group>
              <Form.Group controlId="child_birthdate">
                <Form.Control type="date" name="child_birthdate" placeholder="* Data di nascita..." defaultValue={values.child_birthdate} onChange={updateValues} />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <h5 className="h5 mt30 mb15">Note</h5>
              <Form.Group controlId="notes">
                <Form.Control as="textarea" name="notes" rows="4" placeholder="Note..." defaultValue={values.notes} onChange={updateValues} />
              </Form.Group>
            </div>
            <div className="col-4">
              <h5 className="h5 mt30 mb15">Consenso privacy</h5>
              <Form.Group controlId="privacy">
                <Form.Control as="textarea" rows="4" disabled={true} defaultValue="Nell’esercizio della responsabilità genitoriale sul minore, ai fini dell’utilizzo di questo strumento digitale, acconsento al trattamento dei dati personali da parte di Associazione Contatto Onlus, come indicato nell’informativa." />
                <Form.Text id="privacyHelpBlock" muted>
                  <a href="/#" onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                  }}>Apri l'informativa completa</a>
                </Form.Text>
              </Form.Group>
              <div key="default-checkbox" className="mb-3">
                <Form.Check 
                  type="checkbox"
                  id="privacy"
                  label="* Acconsento"
                  defaultChecked={values.privacy}
                  onClick={() => {updateValues({target: {name: "privacy", value: !values.privacy}})}}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
      <Footer left={(
        <Link to="/home">
          <Button label="Indietro" icon={faArrowLeft} iconPosition="left" />
        </Link>
      )} right={(
        <Button label="Prosegui" icon={faArrowRight} iconPosition="right" onClick={next} />
      )} />
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Informativa sulla privacy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Informazioni in relazione al trattamento dei dati personali ai sensi del Regolamento UE 2016/679 e D.Lgs. 101/2018:</p>
          <ol>
            <li className="mb20">il GDPR e il D.Lgs. 101/2018 prevedono e rafforzano la protezione e il trattamento dei dati personali alla luce dei principi di correttezza, liceità, trasparenza, tutela della riservatezza e dei diritti dell’interessato in merito ai propri dati.</li>
            <li className="mb20">L’Associazione Contatto Onlus è Titolare del trattamento dei seguenti dati raccolti per l’utilizzo di questa applicazione digitale:
            dati personali: dati anagrafici e di contatto – informazioni relative al nome, numero di telefono, indirizzo mail e qualsiasi altro dato o informazione riguardante una persona fisica identificata o identificabile.
            Le riflessioni/valutazioni/interpretazioni professionali tradotte in dati dallo psicologo costituiscono l’insieme dei dati professionali, trattati secondo tutti i principi del GDPR e gestiti/dovuti secondo quanto previsto dal C.D. Non vengono raccolti dati relativi allo stato di salute.</li>
            <li className="mb20">Il trattamento di tutti i dati sopra richiamati viene effettuato sulla base del consenso libero, specifico e informato da parte di chi esercita la responsabilità genitoriale sul/sui minore/i.</li>
            <li className="mb20">I dati personali saranno sottoposti a modalità di trattamento sia cartaceo sia elettronico e/o automatizzato, quindi con modalità sia manuali sia informatiche. </li>
            <li className="mb20">Saranno utilizzate adeguate misure di sicurezza al fine di garantire la protezione, la sicurezza, l’integrità, l’accessibilità dei dati personali, entro i vincoli delle norme vigenti.</li>
            <li className="mb20">I dati personali che non siano più necessari, o per i quali non vi sia più un presupposto giuridico per la relativa conservazione, verranno anonimizzati irreversibilmente o distrutti in modo sicuro.</li>
            <li className="mb20">I dati personali verranno conservati solo per il tempo necessario al conseguimento delle finalità per le quali sono stati raccolti, ovvero i dati anagrafici e di contatto verranno tenuti per il tempo necessario a gestire gli adempimenti contrattuali, quindi per un tempo di 10 anni.</li>
            <li className="mb20">L’eventuale lista dei responsabili del trattamento e degli altri soggetti cui vengono comunicati i dati può essere visionata a richiesta.</li>
            <li className="mb20">Al persistere di talune condizioni, in relazione alle specificità connesse con l’esecuzione dell’incarico, sarà possibile all’interessato esercitare i diritti di cui agli articoli da 15 a 22 del GDPRe D.Lgs. 101/2018 (diritto di accesso ai dati personali, diritto di rettifica, diritto alla cancellazione, diritto alla limitazione del trattamento, diritto alla portabilità ovvero diritto di ottenere copia dei dati personali in un formato strutturato di uso comune e leggibile da dispositivo automatico -in linea di massima trattasi solo di dati inseriti nel computer- e diritto che essi vengano trasmessi a un altro titolare del trattamento). Nel caso di specie sarà onere del Titolare verificare la legittimità delle richieste fornendo riscontro, di regola, entro 30 giorni.</li>
            <li>Per eventuali reclami o segnalazioni sulle modalità di trattamento dei dati è buona norma rivolgersi al Titolare del trattamento dei dati al seguente indirizzo: Associazione Contatto Onlus, via Litta Modignani 61 20161 Milano, mail: associazionecontatto@gmail.com. Tuttavia è possibile inoltrare i propri reclami o le proprie segnalazioni all’Autorità responsabile della protezione dei dati, utilizzando gli estremi di contatto pertinenti: Garante per la protezione dei dati personali - piazza di Montecitorio n.121 - 00186 ROMA - fax: (+39) 06.696773785 - telefono: (+39)06.696771 PEO: garante@gpdp.it - PEC: protocollo@pec.gpdp.it</li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton variant="secondary" onClick={() => setShowModal(false)}>Chiudi</BootstrapButton>
        </Modal.Footer>
      </Modal>
      <Bootbox show={alert !== ''} 
          type={"alert"}  
          message={alert}  
          onClose={() => setAlert('')} 
      />
    </>
  );
}

const mapStateToProps = state => ({
  data: state.survey.data
});

const mapDispatchToProps = dispatch => bindActionCreators({ setTitle, setSurveyData }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditConfiguration)