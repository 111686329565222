import React from 'react';
import { connect } from 'react-redux'

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import './Header.scss';

const Header = props => {
  const { title } = props;
  //
  return (
    <div className="header">
      <div className="row">
        <div className="col-3">
          <Link to="/home">
            <span className="icon"><FontAwesomeIcon icon={faHome} /></span> HOME
          </Link>
        </div>
        <div className="col-6 text-center">
          {title}
        </div>
        <div className="col-3 text-right">
          &nbsp;
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    title: state.title
  }
}

export default connect(
  mapStateToProps
)(Header)