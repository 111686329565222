import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './Button.scss';

// Usage:
// <Button label="Indietro" icon={faArrowLeft} onClick={() => console.log('click')} />

const Button = (props) => {
  const { className, label, icon, iconPosition='left', onClick } = props;
  return (
    <div className={`button ${!icon ? 'without-icon' : ''} icon-${iconPosition} ${className}`} onClick={onClick}>
      {icon && (<span className="icon"><FontAwesomeIcon icon={icon} /></span>)} {label}
    </div>
  );
}

export default Button;