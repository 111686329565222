import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import uuid from 'react-uuid';

import { faArrowLeft, faArrowRight, faTrash } from '@fortawesome/free-solid-svg-icons';

import Bootbox  from  'bootbox-react';
import Form from 'react-bootstrap/Form';

import Button from '../components/Button';
import Footer from '../components/Footer';

import { setSurveyCharacters, setTitle } from '../store/actions';

import Characters from '../components/Characters';
import CharacterImages from '../components/CharacterImages';

import './EditCharacters.scss';

const Categories = [
  {
    'id': 'PAPA',
    'name': 'Papà'
  },
  {
    'id': 'MAMMA',
    'name': 'Mamma'
  },
  {
    'id': 'NONNO',
    'name': 'Nonno'
  },
  {
    'id': 'NONNA',
    'name': 'Nonna'
  },
  {
    'id': 'BIMBO',
    'name': 'Bimbo'
  },
  {
    'id': 'BIMBA',
    'name': 'Bimba'
  },
  {
    'id': 'RAGAZZO',
    'name': 'Ragazzo'
  },
  {
    'id': 'RAGAZZA',
    'name': 'Ragazza'
  },
  {
    'id': 'BEBE',
    'name': 'Bebè'
  },
];

const EditCharacters = props => {
  const history = useHistory();
  const { match: { params: { id } } } = props;
  
  const [alert, setAlert] = useState('');
  const [category, setCategory] = useState('PAPA');
  const [values, setValues] = useState({
    id: id,
    characters: props.characters || []
  });

  const { setSurveyCharacters, setTitle } = props;
  setTimeout(() => setTitle('THINK UP FAMILY LIFE'), 100);

  const next = () => {
    if (values.characters.length === 0) {
      setAlert('Attenzione, seleziona almeno un familiare prima di proseguire');
    } else {
      setSurveyCharacters(values.characters);
      history.push("/" + values.id + "/feelings")
    }
  }

  const addCharacter = (e, item) => {
    e.preventDefault();
    var newCharacters = values.characters.slice(0);
    newCharacters.push({
      id: uuid(),
      key: item,
      variant: 'neutro',
      activity: '',
      name: ''
    });
    setValues({...values, characters: newCharacters });
  }

  const setCharacterName = (e, item) => {
    e.preventDefault();
    var newCharacters = values.characters.map(character => {
      if (character.id === item.id) {
        character.name = e.target.value;
      }
      return character;
    });
    setValues({...values, characters: newCharacters });
  }
  
  const removeCharacter = (e, item) => {
    e.preventDefault();
    var newCharacters = values.characters.filter(character => {
      return character.id !== item.id
    });
    setValues({...values, characters: newCharacters });
  }
  
  var characters = [];
  Object.keys(Characters).forEach(key => {
    if (Characters[key].category === category) {
      characters.push(key);
    }
  });

  return (
    <>
      <div className="container-padding">
        <h5 className="h5">
          <strong>SECONDO STEP</strong>: INSERISCI I FAMILIARI
        </h5>
        <Form>
          <div className="row">
            <div className="col-12 mt20 text-left">
              {Categories.map(item => (
                <Button key={item.id} label={item.name} className={category !== item.id ? 'bg-gray' : null} onClick={() => setCategory(item.id)} />
              ))}
            </div>
            <div className="col-6 mt30">
              {characters.map((item, i) => (
                <a key={`character-${i.toString()}`} className="img-rounded" href="/#" onClick={e => addCharacter(e, item)}>
                  <img src={CharacterImages[`${item}_neutro`]} alt="" />
                </a>
              ))}
            </div>
            <div className="col-6 border-left mt30">
              <h5 className="h5 mt20 mb30">
                PERSONAGGI SELEZIONATI
              </h5>
              {values.characters.map(item => (
                <div key={item.id} className="row bg-gray">
                  <div className="col-sm-2 mt5 text-center">
                    <a className="img-rounded-small" href="/#">
                      <img src={CharacterImages[`${item.key}_neutro`]} alt="" />
                    </a>
                  </div>
                  <div className="col-sm-6 mt30">
                    <Form.Group controlId="name">
                      <Form.Control type="text" name="name" placeholder="Nome personaggio..." defaultValue={item.name} onChange={e => setCharacterName(e, item)} />
                    </Form.Group>
                  </div>
                  <div className="col-sm-4 mt30">
                    <Button label="Rimuovi" icon={faTrash} onClick={e => removeCharacter(e, item)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Form>
      </div>
      <Footer left={(
        <Link to={`/${id}/background`}>
          <Button label="Indietro" icon={faArrowLeft} iconPosition="left" />
        </Link>
      )} right={(
        <Button label="Prosegui" icon={faArrowRight} iconPosition="right" onClick={next} />
      )} />
      <Bootbox show={alert !== ''} 
          type={"alert"}  
          message={alert}  
          onClose={() => setAlert('')} 
      />
    </>
  );
}

const mapStateToProps = state => ({
  characters: state.survey.characters
});

const mapDispatchToProps = dispatch => bindActionCreators({ setSurveyCharacters, setTitle }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCharacters)