import React from 'react';

import Draggable from 'react-draggable';
import Characters from './Characters';

import Actions from './Actions';
import CharacterImages from './CharacterImages';

// Usage:
//
// <DraggableCharacter
//   name="ragazzo_negroide"
//   kind="tristezza"
//   activity="giocare"
//   zoom={35.0}
//   y={25.0}
//   x={50.0}
//   />

function DraggableCharacter(props) {
  const { name, kind, activity, x, y, zoom, setCharacterPosition } = props;
  const image = name + '_' + kind;
  const character = Characters[name] || {};
  const zoomAdj = zoom - zoom * Math.abs(y)/1000;
  const width = character.width * (zoomAdj / 100.0),
        height = character.height * (zoomAdj / 100.0);

  const divStyle = {
    bottom: '0%',
    left: '0%',
    width: width.toString() + 'px',
    height: height.toString() + 'px',
    marginLeft: (-width/2).toString() + 'px',
  };

  const imgStyle = {
    width: width.toString() + 'px',
    height: height.toString() + 'px',
  };

  const activityImgStyle = {
    position: 'absolute',
    left: (character.iconX / character.width * 100.0) + '%',
    top: (character.iconY / character.height * 100.0) + '%',
    width: (width * 0.40).toString() + 'px',
    marginLeft: -(width * 0.18).toString() + 'px',
    marginTop: -(width * 0.18).toString() + 'px'
  };


  const handleStop = (e, ui) => {
    if (setCharacterPosition) {
      setCharacterPosition({
        x: ui.x,
        y: ui.y
      });
    }
  };

  return (
    <Draggable
      axis={setCharacterPosition ? "both" : "none"}
      bounds="parent"
      defaultPosition={{x: x, y: y}}
      grid={[5, 5]}
      scale={1}
      onStop={handleStop}>
      <div className="character" style={divStyle}>
        <img src={CharacterImages[image]} alt="" style={imgStyle} draggable="false" />
        {!!activity && <img src={Actions[activity]} alt="" style={activityImgStyle} draggable="false" />}
      </div>
    </Draggable>
  );
}

export default DraggableCharacter;