import {
  SET_TITLE,
  SET_SURVEY_DATA,
  SET_SURVEY_ANSWERS,
  SET_SURVEY_BACKGROUND,
  SET_SURVEY_CHARACTERS,
  ADD_SURVEY,
  RESET_SURVEY,
  REMOVE_SURVEY
} from "./constants"

const superagent = require('superagent');

const defaultSurvey = {
  id: null,
  answers: {},
  data: {},
  name: '',
  room: '',
  nightMode: false,
  characters: []
};

const defaultStore = {
  title: 'THINK UP FAMILY LIFE',
  survey: defaultSurvey,
  surveys: [],
};

function reducer(state, action) {
  if (typeof state === 'undefined') {
    state = defaultStore;
  }
  switch (action.type) {
    case SET_TITLE:
      return {
        ...state,
        title: action.title
      };
    case SET_SURVEY_ANSWERS:
      state = {
        ...state,
        survey: {
          ...state.survey,
          answers: action.answers
        }
      };
      break;
    case SET_SURVEY_DATA:
      state = {
        ...state,
        survey: {
          ...state.survey,
          data: action.data
        }
      };
      break;
    case SET_SURVEY_BACKGROUND:
      state = {
        ...state,
        survey: {
          ...state.survey,
          name: action.name,
          room: action.room,
          nightMode: action.nightMode
        }
      };
      break;
    case SET_SURVEY_CHARACTERS:
      state = {
        ...state,
        survey: {
          ...state.survey,
          characters: action.characters
        }
      };
      break;
    case ADD_SURVEY:
      let surveys = state.surveys || [];
      surveys = surveys.filter(s => s.id !== action.survey.id)
      surveys.push(action.survey);
      state = {
        ...state,
        surveys: surveys
      };
      break;
    case RESET_SURVEY:
      state = {
        ...state,
        survey: action.survey || defaultSurvey
      };
      break;
    case REMOVE_SURVEY:
      state = {
        ...state,
        surveys: state.surveys.filter(survey => action.survey !== survey)
      };
      break;
    default:
      return state;
  }
  superagent
    .post(window.ENV.SERVER)
    .send({
      action: action.type,
      data: action.survey || state.survey,
    })
    .end(() => {

    });
  return state;
}

export default reducer;