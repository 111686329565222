import React from 'react';

import './CharacterPopup.scss';
import CharacterActions from './CharacterActions';
import Icons from './Icons';

function CharacterPopup(props) {
  const { x, y, zoom, setActivity } = props;
  const size = [1000, 387];
  const width = size[0] * (zoom / 100.0), height = size[1] * (zoom / 100.0);
  const divStyle = {
    top: (y !== undefined ? y : 0).toString() + '%',
    left: (x !== undefined ? x : 0).toString() + '%',
    width: width.toString() + 'px',
    height: height.toString() + 'px',
  };
  const imgStyle = {
    width: (width / 6).toString() + 'px',
  };
  return (
    <div className="character-popup" style={divStyle}>
      <div className="character-popup-actions">
        {CharacterActions.map(action => {
          return <img key={action.name} src={Icons[action.id]} alt={action.name} style={imgStyle} onClick={() => setActivity(action)} />
        })}
      </div>
    </div>
  );
}

export default CharacterPopup;