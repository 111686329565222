import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Bootbox  from  'bootbox-react';
import Form from 'react-bootstrap/Form';

import Button from '../components/Button';
import DraggableCharacter from '../components/DraggableCharacter';
import Footer from '../components/Footer';

import { RoomsImages } from '../components/Rooms';

import { setSurveyCharacters, setTitle } from '../store/actions';

const EditPosition = props => {
  const history = useHistory();
  const { match: { params: { id } } } = props;

  const [alert, setAlert] = useState('');
  const [values, setValues] = useState({
    id: id,
    characters: props.characters || []
  });

  const { setSurveyCharacters, setTitle, room, nightMode, name } = props;
  setTimeout(() => setTitle(name || 'THINK UP FAMILY LIFE'), 100);

  const setCharacterPosition = (item, position) => {
    var newCharacters = values.characters.map(character => {
      if (character.id === item.id) {
        character.position = position;
      }
      return character;
    });
    setValues({...values, characters: newCharacters });
  }

  const next = () => {
    if (values.characters.length === 0) {
      setAlert('Attenzione, seleziona almeno un familiare prima di proseguire');
    } else {
      setSurveyCharacters(values.characters);
      history.push("/" + values.id + "/preview")
    }
  }

  return (
    <>
      <div className="container-padding">
        <h5 className="h5">
          <strong>QUINTO STEP</strong>: POSIZIONA LA TUA FAMIGLIA NELLA STANZA
        </h5>
        <Form>
          <div className="row">
            <div className="col-8 offset-2 mt30">
              <div className="room">
                <img src={RoomsImages[`${room}_${nightMode ? 'notte' : 'giorno'}`]} alt="" className="img-room img-fluid" />
                {values.characters.map((item, i) => (
                  <DraggableCharacter
                    key={i}
                    id={item.id}
                    name={item.key}
                    kind={item.variant}
                    activity={item.activity}
                    zoom={30.0}
                    x={(item.position || {}).x || 0}
                    y={(item.position || {}).y || 0}
                    setCharacterPosition={position => setCharacterPosition(item, position)}
                    />
                ))}
              </div>
            </div>
          </div>
        </Form>
      </div>
      <Footer left={(
        <Link to={`/${id}/activity`}>
          <Button label="Indietro" icon={faArrowLeft} iconPosition="left" />
        </Link>
      )} right={(
        <Button label="Prosegui" icon={faArrowRight} iconPosition="right" onClick={next} />
      )} />
      <Bootbox show={alert !== ''} 
          type={"alert"}  
          message={alert}  
          onClose={() => setAlert('')} 
      />
    </>
  );
}

const mapStateToProps = state => ({
  characters: state.survey.characters,
  room: state.survey.room,
  nightMode: state.survey.nightMode,
  name: state.survey.name
});

const mapDispatchToProps = dispatch => bindActionCreators({ setSurveyCharacters, setTitle }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPosition)