import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import uuid from 'react-uuid';
import moment from 'moment';

import { faArrowRight, faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';

import Bootbox  from  'bootbox-react';
import Table from 'react-bootstrap/Table';

import Button from '../components/Button';
import Footer from '../components/Footer';

import { setTitle, resetSurvey, removeSurvey } from '../store/actions';

const Home = props => {
  const history = useHistory();
  const { setTitle, resetSurvey, removeSurvey, surveys } = props;
  setTimeout(() => setTitle('THINK UP FAMILY LIFE'), 100);
  //
  const newSurvey = () => {
    const surveyId = uuid();
    resetSurvey({
      id: surveyId,
      date: moment().format('DD/MM/YYYY')
    });
    history.push("/" + surveyId + "/edit")
  }
  
  const openSurvey = (e, survey) => {
    resetSurvey(survey);
    history.push("/" + survey.id + "")
  };
  const editSurvey = (e, survey) => {
    resetSurvey(survey);
    history.push("/" + survey.id + "/edit")
  };
  const [confirmDeletion, setConfirmDeletion] = useState(null);
  const deleteSurvey = (e, survey) => {
    setConfirmDeletion(survey);
  };
  const deleteSurveyConfirm = () => {
    let remove = confirmDeletion;
    setConfirmDeletion(null);
    removeSurvey(remove);
  };
  //
  return (
    <>
      <div className="container-padding">
        <h5 className="h5">ELENCO CONFIGURAZIONI</h5>
        <Table className="mt30">
          <thead>
            <tr>
              <th>Nome e Cognome</th>
              <th>Data</th>
              <th>Titolo Scheda</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {surveys.map((survey, i) => (
              <tr key={i.toString()}>
                <td>
                  <div className="mt10">
                    {survey.data.child_firstname} {survey.data.child_lastname}
                  </div>
                </td>
                <td>
                  <div className="mt10">
                    {survey.date}
                  </div>
                </td>
                <td>
                  <div className="mt10">
                    {survey.name}
                  </div>
                </td>
                <td width="200">
                  <Button label="Visualizza" icon={faEye} iconPosition="left" onClick={e => openSurvey(e, survey)} />
                </td>
                <td width="200">
                  <Button label="Modifica" icon={faEdit} iconPosition="left" onClick={e => editSurvey(e, survey)} />
                </td>
                <td width="200">
                  <Button label="Elimina" icon={faTrash} iconPosition="left" onClick={e => deleteSurvey(e, survey)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Footer left={null} right={(
        <Button label="Nuovo" icon={faArrowRight} iconPosition="right" onClick={newSurvey} />
      )} />
      <Bootbox 
        message="Vuoi procedere con l'eliminazione?"
        type={"confirm"}
        show={!!confirmDeletion} 
        successLabel={"Sì"}
        onSuccess={deleteSurveyConfirm}
        onCancel={() => setConfirmDeletion(false)}/>
    </>
  );
}

const mapStateToProps = state => ({
  surveys: state.surveys || []
});

const mapDispatchToProps = dispatch => bindActionCreators({ setTitle, resetSurvey, removeSurvey }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)