const CharacterActions = [
    {
        id: "dormire",
        name: "Dormire",
    },
    {
        id: "giocare",
        name: "Giocare",
    },
    {
        id: "guardare_tv",
        name: "Guardare la TV",
    },
    {
        id: "pulizie",
        name: "Fare le pulizie",
    },
    {
        id: "telefonare",
        name: "Telefonare",
    },
    {
        id: "fumare",
        name: "Fumare",
    },
    {
        id: "bere",
        name: "Bere",
    },
    {
        id: "compiti",
        name: "Fare i compiti",
    },
    {
        id: "default",
        name: "",
    },
];

export default CharacterActions;