import icona_bere from '../imgs/icona_bere_big.png';
import icona_compiti from '../imgs/icona_compiti_big.png';
import icona_default from '../imgs/icona_default_big.png';
import icona_dormire from '../imgs/icona_dormire_big.png';
import icona_fumare from '../imgs/icona_fumare_big.png';
import icona_giocare from '../imgs/icona_giocare_big.png';
import icona_guardare_tv from '../imgs/icona_guardare_tv_big.png';
import icona_pulizie from '../imgs/icona_pulizie_big.png';
import icona_telefonare from '../imgs/icona_telefonare_big.png';


export const Icons = {
    'bere': icona_bere,
    'compiti': icona_compiti,
    'default': icona_default,
    'dormire': icona_dormire,
    'fumare': icona_fumare,
    'giocare': icona_giocare,
    'guardare_tv': icona_guardare_tv,
    'pulizie': icona_pulizie,
    'telefonare': icona_telefonare,
};

export default Icons;