import {
  SET_TITLE,
  SET_SURVEY_DATA,
  SET_SURVEY_ANSWERS,
  SET_SURVEY_BACKGROUND,
  SET_SURVEY_CHARACTERS,
  ADD_SURVEY,
  RESET_SURVEY,
  REMOVE_SURVEY
} from "./constants"

export const setTitle = title => ({
  type: SET_TITLE,
  title: title
});

export const setSurveyData = data => ({
  type: SET_SURVEY_DATA,
  data: data
});

export const setSurveyAnswers = answers => ({
  type: SET_SURVEY_ANSWERS,
  answers: answers
});

export const setSurveyBackground = (room, name, nightMode) => ({
  type: SET_SURVEY_BACKGROUND,
  room: room,
  name: name,
  nightMode: nightMode
});

export const setSurveyCharacters = characters => ({
  type: SET_SURVEY_CHARACTERS,
  characters: characters
});

export const addSurvey = survey => ({
  type: ADD_SURVEY,
  survey: survey
});

export const resetSurvey = (survey = {}) => ({
  type: RESET_SURVEY,
  survey: survey
});

export const removeSurvey = (survey = {}) => ({
  type: REMOVE_SURVEY,
  survey: survey
});