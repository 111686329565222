import React, { forwardRef, useState } from 'react';

import './Character.scss';
import Characters from './Characters';
import CharacterPopup from './CharacterPopup';

import Actions from './Actions';
import CharacterImages from './CharacterImages';

// Usage:
//
// <Character
//   name="ragazzo_negroide"
//   kind="tristezza"
//   activity="giocare"
//   zoom={35.0}
//   y={25.0}
//   x={50.0}
//   />

function Character(props, ref) {
  const [actionsOpen, setActionsOpen] = useState(false);

  const { name, kind, activity, x, y, zoom, setActivity } = props;
  const image = name + '_' + kind;
  const character = Characters[name] || {};
  const width = character.width * (zoom / 100.0),
        height = character.height * (zoom / 100.0);
  const divStyle = {
    bottom: (y !== undefined ? y : 0).toString() + '%',
    left: (x !== undefined ? x : 0).toString() + '%',
    width: width.toString() + 'px',
    height: height.toString() + 'px',
    marginLeft: (-width/2).toString() + 'px',
  };
  const imgStyle = {
    width: width.toString() + 'px',
    height: height.toString() + 'px',
  };
  const activityImgStyle = {
    position: 'absolute',
    left: (character.iconX / character.width * 100.0) + '%',
    top: (character.iconY / character.height * 100.0) + '%',
    width: (width * 0.40).toString() + 'px',
    marginLeft: -(width * 0.18).toString() + 'px',
    marginTop: -(width * 0.18).toString() + 'px'
  };
  
  return (
    <div ref={ref} className="character" style={divStyle} onClick={() => name.substr(0, 4) !== 'baby' && !!setActivity && setActionsOpen(!actionsOpen)}>
      <img src={CharacterImages[image]} alt="" style={imgStyle} />
      {name.substr(0, 4) !== 'baby' && !!activity && !actionsOpen && <img src={Actions[activity]} alt="" style={activityImgStyle} />}
      {name.substr(0, 4) !== 'baby' && actionsOpen && <CharacterPopup x={character.iconX / character.width * 100.0} y={character.iconY / character.height * 100.0} zoom={zoom} setActivity={setActivity} /> }
    </div>
  );
}

export default forwardRef(Character);