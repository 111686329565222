import AZIONE_bere from '../imgs/AZIONE_bere.png';
import AZIONE_compiti from '../imgs/AZIONE_compiti.png';
import AZIONE_default from '../imgs/AZIONE_default.png';
import AZIONE_dormire from '../imgs/AZIONE_dormire.png';
import AZIONE_fumare from '../imgs/AZIONE_fumare.png';
import AZIONE_giocare from '../imgs/AZIONE_giocare.png';
import AZIONE_guardare_tv from '../imgs/AZIONE_guardare_tv.png';
import AZIONE_pulizie from '../imgs/AZIONE_pulizie.png';
import AZIONE_telefonare from '../imgs/AZIONE_telefonare.png';


export const Actions = {
    'bere': AZIONE_bere,
    'compiti': AZIONE_compiti,
    'default': AZIONE_default,
    'dormire': AZIONE_dormire,
    'fumare': AZIONE_fumare,
    'giocare': AZIONE_giocare,
    'guardare_tv': AZIONE_guardare_tv,
    'pulizie': AZIONE_pulizie,
    'telefonare': AZIONE_telefonare,
};

export default Actions;