import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Form from 'react-bootstrap/Form';

import Button from '../components/Button';
import DraggableCharacter from '../components/DraggableCharacter';
import Footer from '../components/Footer';

import { RoomsImages } from '../components/Rooms';

import { setTitle } from '../store/actions';

const View = props => {
  const { match: { params: { id } } } = props;

  const [values, ] = useState({
    id: id,
    characters: props.characters || []
  });

  const { setTitle, room, nightMode, name } = props;
  setTimeout(() => setTitle(name || 'THINK UP FAMILY LIFE'), 100);

  return (
    <>
      <div className="container-padding">
        <Form>
          <div className="row">
            <div className="col-8 offset-2">
              <div className="room">
                <img src={RoomsImages[`${room}_${nightMode ? 'notte' : 'giorno'}`]} alt="" className="img-room img-fluid" />
                {values.characters.map((item, i) => (
                  <DraggableCharacter
                    key={i}
                    id={item.id}
                    name={item.key}
                    kind={item.variant}
                    activity={item.activity}
                    zoom={30.0}
                    x={(item.position || {}).x || 0}
                    y={(item.position || {}).y || 0}
                    />
                ))}
              </div>
            </div>
          </div>
        </Form>
      </div>
      <Footer left={(
        <Link to={"/home"}>
          <Button label="Indietro" icon={faArrowLeft} iconPosition="left" />
        </Link>
      )} />
    </>
  );
}

const mapStateToProps = state => ({
  characters: state.survey.characters,
  room: state.survey.room,
  nightMode: state.survey.nightMode,
  name: state.survey.name
});

const mapDispatchToProps = dispatch => bindActionCreators({ setTitle }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(View)