import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';

import Form from 'react-bootstrap/Form';

import Bootbox  from  'bootbox-react';

import Button from '../components/Button';
import Footer from '../components/Footer';

import { resetSurvey, setSurveyAnswers, addSurvey, setTitle } from '../store/actions';

const EditAnswers = props => {
  const history = useHistory();
  const { match: { params: { id } } } = props;

  const [alert, setAlert] = useState('');
  const [values, setValues] = useState(props.answers || {
    q01: '',
    q02: '',
    q03: '',
    q04: '',
    q05: '',
    q06: '',
    q07: '',
    q08: '',
    q09: '',
    q10: '',
    q11: '',
    q12: '',
    notes: ''
  });

  const updateValues = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  const { setTitle, addSurvey, resetSurvey, setSurveyAnswers, survey } = props;
  setTimeout(() => setTitle('THINK UP FAMILY LIFE'), 100);

const next = () => {
    resetSurvey();
    setSurveyAnswers(values);
    addSurvey({...survey, answers: values});
    history.push("/home")
  };

  return (
    <>
      <div className="container-padding">
        <h5 className="h5">
          ANALISI
          <small>COMPILA IL QUESTIONARIO</small>
        </h5>
        <Form>
          <div className="row">
            <div className="col-4 mt30">
              <Form.Group controlId="q01">
                <Form.Label>1. Come sta il bambino alla fine del lavoro?</Form.Label>
                <Form.Control as="select" name="q01" defaultValue={values.q01} onChange={updateValues}>
                  <option></option>
                  <option>È sereno</option>
                  <option>È triste</option>
                  <option>È preoccupato</option>
                  <option>È arrabbiato</option>
                  <option>Altro (scrivere nelle note)</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4 mt30">
              <Form.Group controlId="q02">
                <Form.Label>2. È stato difficile per il bambino completare il lavoro?</Form.Label>
                <Form.Control as="select" name="q02" defaultValue={values.q02} onChange={updateValues}>
                  <option></option>
                  <option>Sì</option>
                  <option>No</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4 mt30">
              <Form.Group controlId="q03">
                <Form.Label>3. Il bambino ha capito la consegna?</Form.Label>
                <Form.Control as="select" name="q03" defaultValue={values.q03} onChange={updateValues}>
                  <option></option>
                  <option>Per nulla</option>
                  <option>Poco</option>
                  <option>Abbastanza</option>
                  <option>Molto</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4 mt30">
              <Form.Group controlId="q04">
                <Form.Label>4. Il bambino è riuscito a portare a termine il compito richiesto?</Form.Label>
                <Form.Control as="select" name="q04" defaultValue={values.q04} onChange={updateValues}>
                  <option></option>
                  <option>Sì</option>
                  <option>No</option>
                  <option>In parte</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4 mt30">
              <Form.Group controlId="q05">
                <Form.Label>5. Il bambino è stato in grado di comprendere le espressioni emotive?</Form.Label>
                <Form.Control as="select" name="q05" defaultValue={values.q05} onChange={updateValues}>
                  <option></option>
                  <option>Per nulla</option>
                  <option>Poco</option>
                  <option>Abbastanza</option>
                  <option>Molto</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4 mt30">
              <Form.Group controlId="q06">
                <Form.Label>6. Le percezioni del bambino circa uno dei momenti di disfunzione familiare coincidono con quelle espresse dal genitore?</Form.Label>
                <Form.Control as="select" name="q06" defaultValue={values.q06} onChange={updateValues}>
                  <option></option>
                  <option>Per nulla</option>
                  <option>Poco</option>
                  <option>Abbastanza</option>
                  <option>Molto</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4 mt30">
              <Form.Group controlId="q07">
                <Form.Label>7. Il bambino è riuscito a percepire e a rappresentare gli stati emotivi degli altri familiari presenti nella scena?</Form.Label>
                <Form.Control as="select" name="q07" defaultValue={values.q07} onChange={updateValues}>
                  <option></option>
                  <option>Per nulla</option>
                  <option>Poco</option>
                  <option>Abbastanza</option>
                  <option>Molto</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4 mt30">
              <Form.Group controlId="q08">
                <Form.Label>8. Quanto secondo i genitori questa rappresentazione si avvicina alla realtà?</Form.Label>
                <Form.Control as="select" name="q08" defaultValue={values.q08} onChange={updateValues}>
                  <option></option>
                  <option>Per nulla</option>
                  <option>Poco</option>
                  <option>Abbastanza</option>
                  <option>Molto</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4 mt30">
              <Form.Group controlId="q09">
                <Form.Label>9. La madre si aspettava di essere rappresentata così da suo figlio?</Form.Label>
                <Form.Control as="select" name="q09" defaultValue={values.q09} onChange={updateValues}>
                  <option></option>
                  <option>Per nulla</option>
                  <option>Poco</option>
                  <option>Abbastanza</option>
                  <option>Molto</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4 mt30">
              <Form.Group controlId="q10">
                <Form.Label>10. Il padre si aspettava di essere rappresentato così da suo figlio? </Form.Label>
                <Form.Control as="select" name="q10" defaultValue={values.q10} onChange={updateValues}>
                  <option></option>
                  <option>Per nulla</option>
                  <option>Poco</option>
                  <option>Abbastanza</option>
                  <option>Molto</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4 mt30">
              <Form.Group controlId="q11">
                <Form.Label>11. I genitori si aspettavano che il loro figlio si rappresentasse così in quel momento?</Form.Label>
                <Form.Control as="select" name="q11" defaultValue={values.q11} onChange={updateValues}>
                  <option></option>
                  <option>Per nulla</option>
                  <option>Poco</option>
                  <option>Abbastanza</option>
                  <option>Molto</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-4 mt30">
              <Form.Group controlId="q12">
                <Form.Label>12. Lo strumento si è rivelato efficace nel fare esprimere al bambino i suoi vissuti emotivi?</Form.Label>
                <Form.Control as="select" name="q12" defaultValue={values.q12} onChange={updateValues}>
                  <option></option>
                  <option>Per nulla</option>
                  <option>Poco</option>
                  <option>Abbastanza</option>
                  <option>Molto</option>
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <h5 className="h5 mt30 mb15">Note</h5>
              <Form.Group controlId="notes">
                <Form.Control as="textarea" name="notes" rows="4" placeholder="Note..." defaultValue={values.notes} onChange={updateValues} />
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>
      <Footer left={(
        <Link to={`/${id}/position`}>
          <Button label="Indietro" icon={faArrowLeft} iconPosition="left" />
        </Link>
      )} right={(
        <Button label="SALVA" icon={faSave} iconPosition="right" onClick={next} />
      )} />
      <Bootbox show={alert !== ''} 
          type={"alert"}  
          message={alert}  
          onClose={() => setAlert('')} 
      />
    </>
  );
}

const mapStateToProps = state => ({
  survey: state.survey,
  answers: state.survey.answers
});

const mapDispatchToProps = dispatch => bindActionCreators({ setTitle, resetSurvey, addSurvey, setSurveyAnswers }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAnswers)